import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

// :: Lib
import {
  deepAssignKeyValue,
  getDefaultImageProperties,
  getTestProps,
  isImagePreviewSupported,
} from '../../lib/helpers';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';

// :: Components
import Input from '../../components/Input/Input';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import VariantsField from '../../components/VariantsField/VariantsField';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import CustomFormElement from '../ContentObjectForm/CustomFormElement/CustomFormElement';
import FormStateHandler from '../../components/FormStateHandler/FormStateHandler';
import { RolePermissions } from '../../lib/rolePermissions';
import UserContext from '../../contexts/UserContext';

const getInitialValues = (media) => {
  const fileNameGroup = (media?.fileName || '').match(
    /(?<fileName>.*)\.(?<extenstion>[^.]+)$/,
  )?.groups;

  return {
    fileName: fileNameGroup?.fileName || media.fileName || '',
    fileExtension: fileNameGroup?.extenstion,
    alt: media?.alt,
    title: media?.title,
    ...(media?.variants ? { variants: media.variants } : {}),
  };
};

const MediaForm = ({
  media,
  onSubmit,
  navigateOnSave,
  disabled,
  setFormikState,
  formId,
  withoutVariants,
  testId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { buildUrlWithSpace } = useSelectedSpace();
  const noPreview =
    media.type !== 'image' || !isImagePreviewSupported(media.extension);

  const { permissions } = useContext(UserContext);

  const validationSchema = useMemo(
    () =>
      yup.object({
        fileName: yup.string().required(t('Form.FormErrorNotBlank')),
        alt: yup.string(),
        title: yup.string(),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      const newValues = {
        fileName: values.fileExtension
          ? values.fileName + '.' + values.fileExtension
          : values.fileName,
        alt: values.alt,
        title: values.title,
        ...(values.variants ? { variants: values.variants } : {}),
      };

      for (const property in newValues) {
        if (
          !permissions.canCo(
            '_media',
            RolePermissions.PERMISSIONS_TYPES.UPDATE,
            property,
          )
        ) {
          delete newValues[property];
        }
      }

      const [[newMedia, errors], hasErrors] = await onSubmit(newValues);

      if (hasErrors) {
        const parsedErrors = {};
        Object.entries(errors).forEach(([key, value]) => {
          deepAssignKeyValue(key, value, parsedErrors);
        });
        formik.setStatus({ ...formik.status, errors: parsedErrors });
      } else {
        if (navigateOnSave.current) {
          navigate(buildUrlWithSpace('media'));
        } else formik.resetForm({ values: getInitialValues(newMedia) });
      }
    },
    [onSubmit, permissions, navigateOnSave, navigate, buildUrlWithSpace],
  );

  const preview = useMemo(() => {
    if (noPreview) {
      return (
        <div className="flex items-center justify-center w-fit order-first mt-4">
          <div className="flex items-center justify-center rounded-full h-12 w-12 bg-blue-300">
            {media.extension || ''}
          </div>
        </div>
      );
    }
    return (
      <img
        src={getMediaUrl(media)}
        alt={media.fileName}
        className={'max-h-80 object-center object-contain'}
        {...getDefaultImageProperties()}
        {...getTestProps(testId, 'image')}
      />
    );
  }, [media, noPreview, testId]);

  const isFieldDisabled = useCallback(
    (fieldName) =>
      !permissions.canCo(
        '_media',
        RolePermissions.PERMISSIONS_TYPES.UPDATE,
        fieldName,
      ),
    [permissions],
  );

  const isFieldVisible = useCallback(
    (fieldName) =>
      permissions.canCo(
        '_media',
        RolePermissions.PERMISSIONS_TYPES.READ,
        fieldName,
      ),
    [permissions],
  );

  return (
    <Formik
      initialValues={getInitialValues(media)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {(formik) => (
        <form
          id={formId}
          className="flex flex-col gap-4 md:gap-6"
          onSubmit={formik.handleSubmit}
          noValidate={true}
        >
          <div
            className={twMerge(
              'w-full flex flex-col gap-4 md:gap-6',
              !withoutVariants &&
                'p-7 md:p-6 bg-white dark:bg-slate-950 rounded-lg ',
            )}
          >
            <div className={twMerge('max-w-screen-md space-y-4')}>
              <CustomFormElement />
              <div
                className="flex w-full gap-2"
                {...getTestProps(testId, 'inputs')}
              >
                {noPreview && preview}
                {isFieldVisible('fileName') && (
                  <Input
                    name="fileName"
                    label={t('Media.Name')}
                    value={formik.values.fileName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.fileName || formik.status?.errors?.fileName
                    }
                    disabled={isFieldDisabled('fileName') || disabled}
                    required
                    {...getTestProps(testId, 'fileName', 'testId')}
                  />
                )}
                {formik.values.fileExtension && isFieldVisible('extension') && (
                  <Input
                    name="fileExtension"
                    label={t('Media.Extension')}
                    value={formik.values.fileExtension}
                    required
                    disabled
                    additionalClasses="w-max"
                    {...getTestProps(testId, 'extension', 'testId')}
                  />
                )}
              </div>
              {isFieldVisible('title') && (
                <Input
                  name="title"
                  label={t('Media.Title')}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.title || formik.status?.errors?.title}
                  disabled={isFieldDisabled('title') || disabled}
                  additionalClasses={noPreview ? ' row-span-1' : ''}
                  {...getTestProps(testId, 'title', 'testId')}
                />
              )}
              {isFieldVisible('alt') && (
                <Input
                  name="alt"
                  label={t('Media.Alt')}
                  value={formik.values.alt}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.alt || formik.status?.errors?.alt}
                  disabled={isFieldDisabled('alt') || disabled}
                  additionalClasses={noPreview ? ' row-span-2' : ''}
                  {...getTestProps(testId, 'alt', 'testId')}
                />
              )}
              {!noPreview && preview}
            </div>
          </div>
          {!withoutVariants && !noPreview && isFieldVisible('variants') && (
            <>
              <p className="text-lg font-bold dark:text-white">
                {t('MediaEdit.ExistingVariants', {
                  length: formik.values.variants?.length || 0,
                })}
              </p>
              <div className="bg-white dark:bg-slate-950 w-full rounded-lg gap-4 md:gap-6 p-7 md:p-6">
                <FieldArray name="variants">
                  {(arrayHelpers) => (
                    <VariantsField
                      arrayHelpers={arrayHelpers}
                      media={media}
                      errors={formik.status?.errors?.variants}
                      disabled={isFieldDisabled('variants') || disabled}
                      {...getTestProps(testId, 'variants', 'testId')}
                    />
                  )}
                </FieldArray>
              </div>
            </>
          )}
          <DirtyHandler />
          <FormStateHandler setFormikState={setFormikState} />
        </form>
      )}
    </Formik>
  );
};

export default MediaForm;

MediaForm.propTypes = {
  /**
   * On submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * User data
   */
  media: PropTypes.object.isRequired,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback for updating formik state
   */
  setFormikState: PropTypes.func,
  /**
   * Form id
   */
  formId: PropTypes.string,
  /**
   * If variants should be shown in the form
   */
  withoutVariants: PropTypes.bool,
  /**
   * User form test id
   */
  testId: PropTypes.string,
};

MediaForm.defaultProps = {
  disabled: false,
  formId: 'media-form',
  withoutVariants: false,
  testId: '',
};

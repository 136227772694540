import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useStore } from '@tanstack/react-form';
import { batch } from '@tanstack/store';
import { useFormContext } from '../form';

const ValidationToastHandler = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const toastRef = useRef();

  const { isValid, isSubmitting, submissionAttempts } = useStore(
    form.store,
    (state) => ({
      isValid: state.isValid,
      isSubmitting: state.isSubmitting,
      submissionAttempts: state.submissionAttempts,
    }),
  );

  useEffect(() => {
    /**
     * Tanstack form has onSubmitInvalid function in form.
     * Unfortunately it is called only once, so this is workaround
     * to touch all fields on invalid submit
     */
    if (submissionAttempts > 0) {
      batch(() => {
        Object.values(form.fieldInfo).forEach((field) => {
          if (!field.instance) return;
          field.instance.setMeta((prev) => ({ ...prev, isTouched: true }));
        });
      });
    }
  }, [form, submissionAttempts]);

  useEffect(() => {
    if (!isValid && submissionAttempts > 0) {
      if (toastRef.current) toast.remove(toastRef.current);
      toastRef.current = toast.error(t('ContentForm.Errors.TryAgain'));
    }
  }, [isValid, isSubmitting, t, submissionAttempts]);
};

export default ValidationToastHandler;

ValidationToastHandler.propTypes = {};

ValidationToastHandler.defaultProps = {};

import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@tanstack/react-form';
import { useFieldContext, useFormContext } from '../../../form';
import { deepReadKeyValue } from '../../../../lib/helpers';

const GlobalRuleCheckbox = ({ ruleName, disabled, testId }) => {
  const form = useFormContext();
  const field = useFieldContext();

  const permission = useStore(form.store, (state) =>
    deepReadKeyValue(field.name, state.values),
  );

  const isCoType = permission?.type === 'CO';

  const attributesPermission = useMemo(
    () => permission?.attributesPermission,
    [permission?.attributesPermission],
  );

  const fieldsChecked = useMemo(
    () => attributesPermission?.[ruleName],
    [attributesPermission, ruleName],
  );

  const order = useMemo(
    () => permission?.contentTypeDefinition?.metaDefinition?.order || [],
    [permission?.contentTypeDefinition?.metaDefinition?.order],
  );

  const indeterminate = useMemo(
    () =>
      order.length > 0 &&
      fieldsChecked?.length > 0 &&
      fieldsChecked?.length < order.length,
    [fieldsChecked?.length, order.length],
  );

  useEffect(() => {
    if (!permission) return;

    if (
      indeterminate ||
      (order.length > 0 && fieldsChecked?.length === order.length)
    ) {
      form.setFieldValue(`${field.name}.${ruleName}`, true);
    } else if (
      order.length > 0 &&
      fieldsChecked &&
      fieldsChecked.length === 0
    ) {
      form.setFieldValue(`${field.name}.${ruleName}`, false);
    }
  }, [
    field.name,
    fieldsChecked,
    form,
    indeterminate,
    order.length,
    permission,
    ruleName,
  ]);

  const updateCanReadRules = useCallback(
    (value) => {
      form.setFieldValue(`${field.name}.canRead`, true);
      if (isCoType && order?.length > 0) {
        form.setFieldValue(
          `${field.name}.attributesPermission.canRead`,
          value ? order : [],
        );
      }
    },
    [field.name, form, isCoType, order],
  );

  const updateAttributePermissions = useCallback(
    (value) => {
      if (['canCreate', 'canUpdate'].indexOf(ruleName) > -1) {
        form.setFieldValue(
          `${field.name}.attributesPermission.${ruleName}`,
          value ? order : [],
        );

        if (value) {
          form.setFieldValue(
            `${field.name}.attributesPermission.canRead`,
            value ? order : [],
          );
        }
      }

      if (ruleName === 'canRead') {
        form.setFieldValue(
          `${field.name}.attributesPermission.${ruleName}`,
          value
            ? order
            : order.filter(
                (propertyKey) =>
                  attributesPermission?.canCreate?.includes(propertyKey) ||
                  attributesPermission?.canUpdate?.includes(propertyKey),
              ),
        );
      }
    },
    [
      attributesPermission?.canCreate,
      attributesPermission?.canUpdate,
      field.name,
      form,
      order,
      ruleName,
    ],
  );

  const fieldListeners = useMemo(
    () => ({
      onChange: ({ value }) => {
        if (value && ['canCreate', 'canUpdate'].indexOf(ruleName) > -1) {
          updateCanReadRules(value);
        }

        if (isCoType && order?.length > 0) {
          updateAttributePermissions(value);
        }
      },
    }),
    [
      isCoType,
      order?.length,
      ruleName,
      updateAttributePermissions,
      updateCanReadRules,
    ],
  );

  return (
    <form.AppField
      name={`${field.name}.${ruleName}`}
      listeners={fieldListeners}
    >
      {(subField) => (
        <subField.Checkbox
          indeterminate={indeterminate}
          disabled={
            disabled ||
            (ruleName === 'canRead' &&
              (!isCoType || order.length === 0) &&
              (permission?.canCreate || permission?.canUpdate)) ||
            (ruleName === 'canRead' &&
              order.length > 0 &&
              new Set([
                ...(attributesPermission?.canCreate || []),
                ...(attributesPermission?.canUpdate || []),
              ]).size === order.length)
          }
          testId={testId}
        />
      )}
    </form.AppField>
  );
};

export default GlobalRuleCheckbox;

GlobalRuleCheckbox.propTypes = {
  /**
   * Permission rule name
   */
  ruleName: PropTypes.string.isRequired,
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Test id for field
   */
  testId: PropTypes.string,
};

GlobalRuleCheckbox.defaultProps = {
  disabled: false,
  testId: '',
};

import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';
import { CheckmarkIcon, MinusIcon } from '../../images/shapes';

const Checkbox = ({
  id,
  name,
  checked,
  label,
  onChange,
  disabled,
  error,
  circular,
  indeterminate,
  additionalContainerClasses,
  additionalLabelClasses,
  additionalCheckboxContainerClasses,
  additionalCheckboxClasses,
  additionalErrorTextClasses,
  testId,
}) => {
  const checkboxRef = useRef();
  const [currentChecked, setCurrentChecked] = useState(checked);

  useEffect(() => setCurrentChecked(checked), [checked]);

  const onChangeLocal = useCallback(
    async (e) => {
      const value = e.target.checked;
      setCurrentChecked(value);

      const result = await onChange?.(e);
      if (result != null) {
        setCurrentChecked(result);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (indeterminate) {
      checkboxRef.current.indeterminate = true;
    } else {
      checkboxRef.current.indeterminate = false;
    }
  }, [indeterminate]);

  return (
    <div
      className={twMerge('flex w-fit items-center', additionalContainerClasses)}
      {...getTestProps(testId, 'container')}
    >
      <div
        className={twMerge(
          'w-fit h-fit self-start relative',
          additionalCheckboxContainerClasses,
        )}
      >
        {!indeterminate && checked && (
          <CheckmarkIcon
            className="pointer-events-none absolute left-1/4 top-1/4 w-1/2 h-1/2 
                      text-white dark:text-slate-950"
          />
        )}
        {indeterminate && (
          <MinusIcon
            className="pointer-events-none absolute left-[15%] top-1/4 w-[70%] h-1/2 
                      text-white dark:text-slate-950"
          />
        )}
        <input
          ref={checkboxRef}
          name={name}
          id={id || name}
          className={twMerge(
            'flex items-center justify-center hover:cursor-pointer text-white appearance-none',
            'w-6 h-6 bg-transparent dark:bg-transparent checked:bg-blue dark:checked:bg-blue',
            'border border-blue dark:border-blue',
            indeterminate && 'bg-blue dark:bg-blue disabled:bg-gray-400',
            circular ? 'rounded-full' : 'rounded',
            'disabled:cursor-not-allowed disabled:hover:cursor-not-allowed',
            'disabled:checked:bg-gray-400 disabled:border-gray-400',
            additionalCheckboxClasses,
          )}
          type="checkbox"
          checked={currentChecked}
          onChange={onChangeLocal}
          disabled={disabled}
          {...getTestProps(testId, 'checkbox')}
        />
      </div>

      <div className="flex flex-col">
        {label && (
          <label
            htmlFor={name}
            className={twMerge(
              'cursor-pointer text-left text-lg ml-1.5 dark:text-white',
              disabled && 'cursor-not-allowed hover:cursor-not-allowed',
              additionalLabelClasses,
            )}
            {...getTestProps(testId, 'label')}
          >
            {label}
          </label>
        )}

        {error && (
          <span
            className={twMerge(
              'text-red mt-1 mb-2 leading-none',
              additionalErrorTextClasses,
            )}
            {...getTestProps(testId, 'error')}
          >
            {error}
          </span>
        )}
      </div>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  /**
   * Checkbox id
   */
  id: PropTypes.string,
  /**
   * Checkbox name
   */
  name: PropTypes.string,
  /**
   * On checked value change handler
   */
  onChange: PropTypes.func,
  /**
   * Checkbox value
   */
  checked: PropTypes.bool,
  /**
   * If checkbox is indeterminate
   */
  indeterminate: PropTypes.bool,
  /**
   * Checkbox label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * If checkbox is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Checkbox text that will inform about error
   */
  error: PropTypes.string,
  /**
   * If checkbox is circular
   */
  circular: PropTypes.bool,
  /**
   * Checkbox additional container classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Checkbox additional label classes
   */
  additionalLabelClasses: PropTypes.string,
  /**
   * Checkbox container additional label classes
   */
  additionalCheckboxContainerClasses: PropTypes.string,
  /**
   * Checkbox additional classes
   */
  additionalCheckboxClasses: PropTypes.string,
  /**
   * Checkbox additional text error classes
   */
  additionalErrorTextClasses: PropTypes.string,
  /**
   * Checkbox test id
   */
  testId: PropTypes.string,
};

Checkbox.defaultProps = {
  id: '',
  name: '',
  checked: false,
  label: '',
  disabled: false,
  error: '',
  onChange: undefined,
  circular: false,
  indeterminate: false,
  additionalContainerClasses: '',
  additionalLabelClasses: '',
  additionalCheckboxContainerClasses: '',
  additionalCheckboxClasses: '',
  additionalErrorTextClasses: '',
  testId: '',
};

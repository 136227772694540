import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';
import { z } from 'zod';
import { useAppForm } from '../../form-hooks/form';
import { getInitialValues } from './helpers/initialValues';
import { transformValues } from './helpers/submitTransform';
import { getTestProps } from '../../lib/helpers';
import Tooltip from '../../components/Tooltip/Tooltip';
import { QuestionMarkCircleIcon } from '../../images/shapes';

const UserRoleForm = ({
  role,
  onSave,
  contentTypes,
  disabled,
  navigateOnSave,
  duplicate,
  testId,
}) => {
  const { t } = useTranslation();
  const { buildUrlWithSpace } = useSelectedSpace();
  const { navigateGrid } = useGridNavigate(
    'user-roles',
    buildUrlWithSpace('user-roles'),
  );

  const ctdsOptions = useMemo(
    () =>
      contentTypes.map((ctd) => ({
        value: ctd.id,
        label: ctd.label,
        metaDefinition: ctd.metaDefinition,
        searchString: ctd.name + ' ' + ctd.label,
      })),
    [contentTypes],
  );

  const validationSchemaZod = useMemo(
    () =>
      z.object({
        name: z.string().min(1, t('Form.FormErrorNotBlank')),
        description: z.string().min(1, t('Form.FormErrorNotBlank')),
        permissions: z
          .array(
            z.object({
              type: z.string().min(1, t('Form.FormErrorNotBlank')),
              contentTypeDefinition: z
                .object({
                  id: z.string().nullable(),
                })
                .refine(
                  (value) => {
                    if (!value?.id) return true;
                    return (
                      ctdsOptions?.findIndex((el) => el.value === value?.id) >=
                      0
                    );
                  },
                  {
                    message: t('ApiKeys.ErrorSelectedNoLongerValid'),
                  },
                ),
            }),
          )
          .min(1, t('UserRoles.PermissionsMinLength')),
      }),
    [ctdsOptions, t],
  );

  const ruleColumn = useMemo(() => {
    return ['Read', 'Create', 'Update', 'Delete'].map((perm) => ({
      id: `can${perm}`,
      name: `can${perm}`,
      label: t(`Global.Permission.${perm}`),
      isFieldPermission: perm !== 'Delete',
    }));
  }, [t]);

  const onFormSubmit = useCallback(
    async ({ value, formApi }) => {
      const [, errors, hasErrors] = await onSave(transformValues(value));

      if (!hasErrors) {
        if (navigateOnSave?.current) {
          navigateGrid();
        }
      } else {
        Object.entries(errors).forEach(([key, error]) => {
          formApi.setFieldMeta(key, (prev) => ({
            ...prev,
            errorMap: { onSubmit: error },
          }));
        });
      }
    },
    [navigateGrid, navigateOnSave, onSave],
  );

  const initialValues = useMemo(
    () =>
      role
        ? getInitialValues(role, ruleColumn, contentTypes, duplicate)
        : {
            name: '',
            description: '',
            permissions: [],
          },
    [contentTypes, duplicate, role, ruleColumn],
  );

  const form = useAppForm({
    defaultValues: initialValues,
    onSubmit: onFormSubmit,
    validators: {
      onChange: validationSchemaZod,
    },
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      form.handleSubmit();
    },
    [form],
  );

  return (
    <form.AppForm>
      <form
        className="max-w-4xl py-4 px-5"
        id="role-form"
        onSubmit={handleSubmit}
        noValidate={true}
      >
        <div className="flex flex-col gap-4 md:gap-6 w-full">
          <form.AppField name="name">
            {(field) => (
              <field.Input
                label={t('Global.Name')}
                disabled={disabled}
                required
                {...getTestProps(testId, 'name', 'testId')}
              />
            )}
          </form.AppField>

          <form.AppField name="description">
            {(field) => (
              <field.Textarea
                label={t('Global.Description')}
                disabled={disabled}
                required
                {...getTestProps(testId, 'description', 'testId')}
              />
            )}
          </form.AppField>
        </div>

        <form.Subscribe
          selector={({ fieldMeta }) => ({
            isTouched: fieldMeta.permissions?.isTouched || false,
            errors: fieldMeta.permissions?.errors || [],
          })}
        >
          {({ isTouched, errors }) =>
            isTouched && errors.length > 0 ? (
              <div
                className="text-red text-sm mt-10 leading-none text-left"
                {...getTestProps(testId, 'permissions-error')}
              >
                {errors
                  .map((error) =>
                    typeof error === 'string' ? error : error.message,
                  )
                  .join(', ')}
              </div>
            ) : null
          }
        </form.Subscribe>

        <div className="flex mt-10 mb-3 items-center">
          <h2 className="text-3xl leading-none dark:text-white">
            {t('UserRoles.ContentTypeDefinitionRules')}
          </h2>

          <Tooltip
            tooltip={
              <Trans
                i18nKey="UserRoles.ContentTypeDefinitionRulesTooltip"
                components={{
                  1: <ul className="list-disc ml-4 mt-1" />,
                  2: <li />,
                }}
              />
            }
            phoneTooltipPlacement="topRight"
            additionalClasses="px-2"
          >
            <QuestionMarkCircleIcon className="text-slate-400 min-w-4 w-4" />
          </Tooltip>
        </div>

        <form.AppField name={'permissions'} mode="array">
          {(field) => (
            <field.PermissionsField
              ruleColumn={ruleColumn}
              ctdsOptions={ctdsOptions}
              disabled={disabled}
              type="CTD"
              ctdPlaceholder={t('UserRoles.All')}
              {...getTestProps(testId, 'ctd-permissions', 'testId')}
            />
          )}
        </form.AppField>

        <div className="flex mt-10 mb-3 items-center">
          <h2 className="text-3xl leading-none dark:text-white">
            {t('UserRoles.ContentObjectRules')}
          </h2>

          <Tooltip
            tooltip={
              <Trans
                i18nKey="UserRoles.ContentObjectRulesTooltip"
                components={{
                  1: <ul className="list-disc ml-4 my-1" />,
                  2: <li />,
                }}
              />
            }
            phoneTooltipPlacement="topRight"
            additionalClasses="px-2"
          >
            <QuestionMarkCircleIcon className="text-slate-400 min-w-4 w-4" />
          </Tooltip>
        </div>

        <form.AppField name={'permissions'} mode="array">
          {(field) => (
            <field.PermissionsField
              ruleColumn={ruleColumn}
              ctdsOptions={ctdsOptions}
              disabled={disabled}
              type="CO"
              ctdPlaceholder={t('UserRoles.All')}
              {...getTestProps(testId, 'co-permissions', 'testId')}
            />
          )}
        </form.AppField>

        <form.DirtyHandler />
        <form.ValidationToastHandler />
      </form>
    </form.AppForm>
  );
};

export default UserRoleForm;

UserRoleForm.propTypes = {
  /**
   * User role item
   */
  role: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        contentTypeDefinition: PropTypes.shape({ id: PropTypes.string }),
        type: PropTypes.string,
      }),
    ),
  }),
  /**
   * On save callback
   */
  onSave: PropTypes.func.isRequired,
  /**
   * Content types data
   */
  contentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If role is duplicating
   */
  duplicate: PropTypes.bool,
  /**
   * Test id for form
   */
  testId: PropTypes.string,
};

UserRoleForm.defaultProps = {
  contentTypes: [],
  disabled: false,
  duplicate: false,
  testId: '',
};

import { useCallback, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router-dom';

// :: Components
import ValidationToastHandler from '../../components/ValidationToastHandler/ValidationToastHandler';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import CtoCustomField from '../../components/CtoCustomField/CtoCustomField';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';
import CustomFormElement from './CustomFormElement/CustomFormElement';
import VariantModalForm from '../VariantModalForm/VariantModalForm';

// :: Contexts
import { useModals } from '../../contexts/ModalContext';

// :: Hooks
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';

// :: Lib
import { generateYupShapeForCto } from './helpers/generateYupShapeForCto';
import { getDefaultValueForType, getTestProps } from '../../lib/helpers';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import { FormAfterSubmitEvent } from '../../lib/flotiq-plugins/plugin-events/FormAfterSubmitEvent';
import FormStateHandler from '../../components/FormStateHandler/FormStateHandler';
import useMediaUpdateVariants from '../../hooks/api/useMediaUpdateVariants';
import UserContext from '../../contexts/UserContext';
import { RolePermissions } from '../../lib/rolePermissions';

const getInitialData = (properties, schema, contentObject, hasData) =>
  Object.keys(properties).reduce((initials, key) => {
    const defaultDate = moment(
      schema[key].default,
      moment.ISO_8601,
      true,
    ).isValid()
      ? schema[key].default
      : null;
    const defaultValue =
      properties[key].inputType === 'dateTime'
        ? defaultDate
        : schema[key].default;
    const defaultForType = getDefaultValueForType(schema[key].type);
    const init = hasData ? contentObject[key] : defaultValue;
    initials[key] = init || defaultForType;
    return initials;
  }, {});

const ContentObjectForm = ({
  contentObject,
  contentType,
  isEditing,
  onSubmit,
  disabled,
  navigateOnSave,
  hasInitialData,
  formId,
  userPlugins,
  onValidate,
  disabledBuildInValidation,
  additionalFormClasses,
  testId,
  isPatchable,
  overriddenFields,
  setOverriddenFields,
  editedCount,
  formikRef,
  formUniqueKey,
  setFormikState,
  renderField,
  setAddedFieldsCount,
}) => {
  const { t } = useTranslation();
  const { contentTypeName } = useParams();
  const { buildUrlWithSpace } = useSelectedSpace();
  const modal = useModals();
  const { updateVariants } = useMediaUpdateVariants();
  const { permissions } = useContext(UserContext);

  const { navigateGrid } = useGridNavigate(
    `objects-${contentTypeName}`,
    buildUrlWithSpace(`content-type-objects/${contentTypeName}`),
  );

  const schema = useMemo(() => {
    if (!contentType?.schemaDefinition?.allOf[1]?.properties) return {};
    return Object.entries(
      contentType.schemaDefinition.allOf[1].properties,
    ).reduce((acc, [key, value]) => {
      if (
        !permissions.canCo(
          contentTypeName,
          RolePermissions.PERMISSIONS_TYPES.READ,
          key,
        )
      )
        return acc;
      acc[key] = value;
      return acc;
    }, {});
  }, [contentType, contentTypeName, permissions]);

  const properties = useMemo(() => {
    if (!contentType?.metaDefinition?.propertiesConfig) return {};
    if (!contentType.metaDefinition?.order)
      return contentType.metaDefinition?.propertiesConfig;
    return contentType.metaDefinition.order.reduce((acc, key) => {
      if (
        !permissions.canCo(
          contentTypeName,
          RolePermissions.PERMISSIONS_TYPES.READ,
          key,
        )
      )
        return acc;
      acc[key] = contentType.metaDefinition?.propertiesConfig[key];
      return acc;
    }, {});
  }, [contentType, permissions, contentTypeName]);

  const requiredFields = useMemo(() => {
    if (!contentType?.schemaDefinition?.required || isPatchable) return {};
    return contentType.schemaDefinition?.required.reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
  }, [contentType.schemaDefinition?.required, isPatchable]);

  const validationObject = useMemo(
    () =>
      generateYupShapeForCto(
        schema,
        t,
        requiredFields,
        properties,
        undefined,
        isPatchable,
      ),
    [schema, t, requiredFields, properties, isPatchable],
  );

  const renderFormField = useCallback(
    (key, props, schema, isRequired) =>
      renderField ? (
        renderField(key, props, schema, isRequired)
      ) : (
        <CtoCustomField
          key={key}
          name={key}
          properties={props}
          schema={schema}
          isRequired={isRequired}
          disabled={
            disabled ||
            !permissions.canCo(
              contentTypeName,
              isEditing
                ? RolePermissions.PERMISSIONS_TYPES.UPDATE
                : RolePermissions.PERMISSIONS_TYPES.CREATE,
              key,
            )
          }
          additionalClasses="max-w-3xl"
          testId={testId}
        />
      ),
    [contentTypeName, disabled, isEditing, permissions, renderField, testId],
  );

  const initialValues = useMemo(
    () => getInitialData(properties, schema, contentObject, hasInitialData),
    [properties, schema, contentObject, hasInitialData],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      if (!onSubmit) return [values, null];

      const newValues = { ...values };
      for (const property in newValues) {
        if (
          !permissions.canCo(
            contentTypeName,
            isEditing
              ? RolePermissions.PERMISSIONS_TYPES.UPDATE
              : RolePermissions.PERMISSIONS_TYPES.CREATE,
            property,
          )
        ) {
          delete newValues[property];
        }
      }

      const [newObject, errors] = await onSubmit(newValues);
      formik.setStatus({ ...formik.status, errors });

      const success = !errors || !Object.keys(errors).length;

      if (success) {
        if (navigateOnSave?.current) {
          navigateGrid();
        } else {
          formik.resetForm({
            values: getInitialData(properties, schema, newObject, true),
          });
        }
      }

      FlotiqPlugins.run(
        'flotiq.form::after-submit',
        new FormAfterSubmitEvent({
          success,
          contentObject: newObject,
          errors: !success ? errors : null,
          userPlugins,
        }),
      );

      return [newObject, errors];
    },
    [
      onSubmit,
      userPlugins,
      permissions,
      contentTypeName,
      isEditing,
      navigateOnSave,
      navigateGrid,
      properties,
      schema,
    ],
  );

  const onVariantCreate = useCallback(
    async (media) => {
      const newVariant = await modal({
        title: t('MediaEdit.AddVariant'),
        content: (
          <VariantModalForm
            media={media}
            variantsNames={(media.variants || []).map(
              (variant) => variant.name,
            )}
          />
        ),
        size: 'xl',
        dialogAdditionalClasses: 'max-h-screen',
      });

      const newVariants = [...(media.variants || []), newVariant];

      if (newVariant) {
        return await updateVariants(media.id, newVariants);
      }

      return [null, false];
    },
    [modal, t, updateVariants],
  );

  const onVariantDelete = useCallback(
    async (media, idx) => {
      if (!media?.variants?.length) return [];

      const newVariants = media.variants.filter(
        (_, variantIdx) => idx !== variantIdx,
      );

      return await updateVariants(media.id, newVariants, true);
    },
    [updateVariants],
  );

  const contentObjectFormContextValue = useMemo(
    () => ({
      contentType,
      isEditing,
      initialData: contentObject,
      onVariantCreate,
      onVariantDelete,
      userPlugins,
      isPatchable,
      overriddenFields,
      setOverriddenFields,
      editedCount,
      formUniqueKey,
    }),
    [
      contentObject,
      contentType,
      isEditing,
      onVariantCreate,
      onVariantDelete,
      userPlugins,
      isPatchable,
      overriddenFields,
      setOverriddenFields,
      editedCount,
      formUniqueKey,
    ],
  );

  return (
    <Formik
      initialValues={JSON.parse(JSON.stringify(initialValues))}
      onSubmit={handleSubmit}
      validationSchema={
        disabledBuildInValidation ? null : yup.object().shape(validationObject)
      }
      validate={(values) => onValidate?.(values)}
      innerRef={formikRef}
    >
      <ContentObjectFormContext.Provider value={contentObjectFormContextValue}>
        <Form
          id={formId}
          className={twMerge(
            'flex flex-col space-y-3 md:space-y-6 pb-0 md:pb-5',
            additionalFormClasses,
          )}
          noValidate
          {...getTestProps(testId, 'formik')}
        >
          <CustomFormElement setCount={setAddedFieldsCount} />
          {Object.keys(properties).map((key) =>
            renderFormField(
              key,
              properties[key],
              schema[key],
              requiredFields[key],
            ),
          )}
          <ValidationToastHandler />
          <DirtyHandler />
          <FormStateHandler setFormikState={setFormikState} />
        </Form>
      </ContentObjectFormContext.Provider>
    </Formik>
  );
};

export default ContentObjectForm;

ContentObjectForm.propTypes = {
  /**
   * Content type
   */
  contentType: PropTypes.object.isRequired,
  /**
   * On submit callback
   */
  onSubmit: PropTypes.func,
  /**
   * Content object to edit
   */
  contentObject: PropTypes.object,
  /**
   * If form is used for object editing
   */
  isEditing: PropTypes.bool,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Does the object have initial data?
   */
  hasInitialData: PropTypes.bool,
  /**
   * Content object form id
   */
  formId: PropTypes.string,
  /**
   * User plugins settings
   */
  userPlugins: PropTypes.array,
  /**
   * On validate callback
   */
  onValidate: PropTypes.func,
  /**
   * Form enable reinitialize
   */
  enableReinitialize: PropTypes.bool,
  /**
   * If build in validation is off
   */
  disabledBuildInValidation: PropTypes.bool,
  /**
   * Additional form classes
   */
  additionalFormClasses: PropTypes.string,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
  /**
   * Should the form have switches for enabling fields
   */
  isPatchable: PropTypes.bool,
  /**
   * List of fields to patch
   */
  overriddenFields: PropTypes.shape({}),
  /**
   * Function to set overridden fields
   */
  setOverriddenFields: PropTypes.func,
  /**
   * Count of edited objects
   */
  editedCount: PropTypes.number,
  /**
   * Formik context reference
   */
  formikRef: PropTypes.any,
  /**
   * Unique key for form. Used in plugins to distingushe different forms and in editors (eg. block editor).
   */
  formUniqueKey: PropTypes.any,
  /**
   * Callback for updating formik state
   */
  setFormikState: PropTypes.func,
};

ContentObjectForm.defaultProps = {
  contentObject: {},
  isEditing: false,
  disabled: false,
  hasInitialData: false,
  formId: 'cto-form',
  disabledBuildInValidation: false,
  additionalFormClasses: '',
  testId: '',
  isPatchable: false,
  overriddenFields: {},
  setOverriddenFields: null,
  editedCount: 1,
};

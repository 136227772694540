import { useCallback } from 'react';
import { useFieldContext } from '../../form';
import Textarea from '../../../components/Textarea/Textarea';
import { useFieldErros } from '../../useFieldErrors';

const TextareaField = (props) => {
  const field = useFieldContext();
  const errors = useFieldErros();

  const onChange = useCallback(
    (e) => field.handleChange(e.target.value),
    [field],
  );

  return (
    <Textarea
      {...props}
      name={field.name}
      value={field.state.value}
      onChange={onChange}
      onBlur={field.handleBlur}
      error={errors}
    />
  );
};

export default TextareaField;

TextareaField.propTypes = Textarea.propTypes;

TextareaField.defaultProps = Textarea.defaultProps;

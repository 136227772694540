import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFieldContext, useFormContext } from '../../../form';
import Checkbox from '../../../../components/Checkbox/Checkbox';

const RuleCheckbox = ({ field, propertyKey, disabled, testId }) => {
  const fieldIndex = useMemo(
    () => field.state.value?.findIndex((key) => key === propertyKey),
    [field.state.value, propertyKey],
  );

  const onChange = useCallback(
    (e) => {
      const checked = e.target.checked;
      if (checked) {
        field.pushValue(propertyKey);
      } else {
        field.removeValue(fieldIndex);
      }
    },
    [field, fieldIndex, propertyKey],
  );

  return (
    <Checkbox
      name={field.name}
      checked={fieldIndex > -1}
      onChange={onChange}
      onBlur={field.handleBlur}
      disabled={disabled}
      testId={testId}
    />
  );
};

const FieldRuleCheckbox = ({ ruleName, propertyKey, disabled, testId }) => {
  const form = useFormContext();
  const field = useFieldContext();

  const attributesValue = useMemo(() => field.state.value, [field.state.value]);

  const listeners = useMemo(
    () =>
      ['canCreate', 'canUpdate'].indexOf(ruleName) > -1
        ? {
            onChange: ({ value }) => {
              if (value) {
                form.setFieldValue(
                  `${field.name}.canRead`,
                  Array.from(
                    new Set([...(attributesValue?.canRead || []), ...value]),
                  ),
                );
              }
            },
          }
        : {},
    [attributesValue?.canRead, field.name, form, ruleName],
  );

  return (
    <form.Field
      name={`${field.name}.${ruleName}`}
      mode="array"
      listeners={listeners}
    >
      {(subField) => (
        <RuleCheckbox
          field={subField}
          propertyKey={propertyKey}
          disabled={
            disabled ||
            (ruleName === 'canRead' &&
              (attributesValue?.canCreate?.includes(propertyKey) ||
                attributesValue?.canUpdate?.includes(propertyKey)))
          }
          testId={testId}
        />
      )}
    </form.Field>
  );
};

export default FieldRuleCheckbox;

FieldRuleCheckbox.propTypes = {
  /**
   * Permission rule name
   */
  ruleName: PropTypes.string.isRequired,
  /**
   * Content type property name
   */
  propertyKey: PropTypes.string.isRequired,
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
};

FieldRuleCheckbox.defaultProps = {
  disabled: false,
};

export const getInitialValues = (
  role,
  ruleColumn,
  contentTypes = [],
  duplicate = false,
) => ({
  name: role.name,
  description: role.description,
  permissions: role.permissions.map(({ id, ...permission }) => {
    const ctdId = permission.contentTypeDefinition?.id;
    const contentType = contentTypes.find(({ id }) => ctdId === id);
    const order = contentType?.metaDefinition?.order;
    return {
      ...permission,
      id: duplicate ? undefined : id,
      contentTypeDefinition: ctdId
        ? {
            id: ctdId,
            metaDefinition: contentType?.metaDefinition,
            required: contentType?.schemaDefinition?.required,
          }
        : { id: null },
      attributesPermission: getInitialAttributes(permission, ruleColumn, order),
    };
  }),
});

const getInitialAttributes = (permission, ruleColumn, order) => {
  if (permission.type === 'CTD' || !permission.attributesPermission) return;

  const newAttributes = ruleColumn
    .filter(({ isFieldPermission }) => isFieldPermission)
    .reduce((acc, { name }) => {
      if (permission.attributesPermission[name]?.length) {
        acc[name] = permission.attributesPermission[name];
      } else if (
        permission[name] &&
        !permission.attributesPermission[name] &&
        order
      ) {
        acc[name] = order;
      }
      return acc;
    }, {});
  return Object.keys(newAttributes).length > 0 ? newAttributes : undefined;
};

import { useCallback } from 'react';
import { useFieldContext } from '../../form';
import Input from '../../../components/Input/Input';
import { useFieldErros } from '../../useFieldErrors';

const InputField = (props) => {
  const field = useFieldContext();
  const errors = useFieldErros();

  const onChange = useCallback(
    (e) => field.handleChange(e.target.value),
    [field],
  );

  return (
    <Input
      {...props}
      name={field.name}
      value={field.state.value}
      onChange={onChange}
      onBlur={field.handleBlur}
      error={errors}
    />
  );
};

export default InputField;

InputField.propTypes = Input.propTypes;

InputField.defaultProps = Input.defaultProps;

import { useCallback, useContext, useId, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useContentObject, useContentType } from '../../../hooks/api';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import { ModalInstanceContext } from '../../../contexts/ModalContext';
import ContentObjectForm from '../../../form/ContentObjectForm/ContentObjectForm';
import Loader from '../../Loader/Loader';
import {
  handleCTOErrors,
  updateCTO,
} from '../../../lib/flotiq-client/api-helpers';
import { getTestProps } from '../../../lib/helpers';
import { batchPatchContentObject } from '../../../lib/flotiq-client';
import useToken from '../../../hooks/useToken';
import useSelectedSpace from '../../../hooks/useSelectedSpace';
import { checkResponseStatus } from '../../../lib/flotiq-client/response-errors';
import { toast } from 'react-hot-toast';
import TagManager from 'react-gtm-module';
import CancelButton from '../../Button/predefined/CancelButton/CancelButton';
import SaveButton from '../../Button/predefined/SaveButton/SaveButton';
import MediaForm from '../../../form/MediaForm/MediaForm';
import UserContext from '../../../contexts/UserContext';

const EditObjectContentModal = ({
  contentTypeName,
  id,
  ids,
  testId,
  isPatchable,
}) => {
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const formId = useId();
  const { t } = useTranslation();
  const modalInstance = useContext(ModalInstanceContext);
  const [isSaving, setIsSaving] = useState(false);
  const [overriddenFields, setOverriddenFields] = useState({});

  const isMedia = contentTypeName === '_media';

  const { permissions } = useContext(UserContext);

  const onClose = useCallback(
    (values) => modalInstance.resolve(values),
    [modalInstance],
  );

  const {
    entity: contentObject,
    isLoading: contentObjectIsLoading,
    updateEntity: updateContentObject,
    partialUpdateEntity: partialUpdateContentObject,
    errors: contentObjectErrors,
  } = useContentObject(contentTypeName, id);

  const contentTypeOptions = useMemo(
    () => ({
      pause: isMedia,
    }),
    [isMedia],
  );

  const {
    entity: contentType,
    errors: contentTypeErrors,
    isLoading: contentTypeLoading,
  } = useContentType(contentTypeName, contentTypeOptions);

  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(contentObjectErrors);

  const updateObject = useCallback(
    async (values) => {
      setIsSaving(true);
      if (isPatchable) {
        const valuesToOverride = {};
        Object.keys(overriddenFields).forEach((key) => {
          if (overriddenFields[key]) {
            valuesToOverride[key] = values[key];
          }
        });
        const batchValues = [];
        ids.forEach((_id) => {
          batchValues.push({
            id: _id,
            ...valuesToOverride,
          });
        });
        try {
          const { status, body } = await batchPatchContentObject(
            jwt,
            space,
            contentTypeName,
            batchValues,
          );
          checkResponseStatus(body, status);
          toast.success(
            t('ContentForm.SavedMultiple', {
              count: body.batch_success_count,
            }),
          );

          TagManager.dataLayer({
            dataLayer: {
              event: 'mass_co_change',
            },
          });

          setIsSaving(false);
          onClose(body);

          return [body, {}];
        } catch (error) {
          setIsSaving(false);
          const [formikValues, hasErrors] = handleCTOErrors(
            error,
            valuesToOverride,
            t,
          );
          if (!hasErrors) onClose(formikValues);
          return formikValues;
        }
      } else {
        const [formikValues, hasErrors] = await updateCTO(
          values,
          contentTypeName,
          permissions.hasCoLimitedFields(contentTypeName) || isMedia
            ? partialUpdateContentObject
            : updateContentObject,
          t,
        );
        setIsSaving(false);
        if (!hasErrors) onClose(formikValues);
        return formikValues;
      }
    },
    [
      isPatchable,
      overriddenFields,
      ids,
      jwt,
      space,
      contentTypeName,
      t,
      onClose,
      permissions,
      isMedia,
      partialUpdateContentObject,
      updateContentObject,
    ],
  );

  const updateOverriddenFields = useCallback(
    (checked, key) =>
      setOverriddenFields((prev) => ({ ...prev, [key]: checked })),
    [],
  );

  const initialData = useMemo(() => contentObject || {}, [contentObject]);

  const modalContent = useMemo(() => {
    const isLoading =
      (contentObjectIsLoading && !isPatchable) || contentTypeLoading;
    if (isLoading)
      return (
        <div className="w-full h-full flex items-center justify-center">
          <Loader size="big" type="spinner-grid" />
        </div>
      );

    if (isMedia)
      return (
        <MediaForm
          formId={formId}
          media={initialData}
          onSubmit={updateObject}
          disabled={isSaving}
          withoutVariants
        />
      );
    return (
      <ContentObjectForm
        contentType={contentType}
        contentObject={initialData}
        onSubmit={updateObject}
        disabled={isSaving}
        formId={formId}
        hasInitialData
        testId={testId}
        isPatchable={isPatchable}
        isEditing={isPatchable || !!id}
        overriddenFields={overriddenFields}
        setOverriddenFields={updateOverriddenFields}
        editedCount={ids?.length}
      />
    );
  }, [
    contentObjectIsLoading,
    contentType,
    contentTypeLoading,
    formId,
    id,
    ids?.length,
    initialData,
    isMedia,
    isPatchable,
    isSaving,
    overriddenFields,
    testId,
    updateObject,
    updateOverriddenFields,
  ]);

  return (
    <>
      {modalContent}
      <div
        className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
        border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900"
      >
        <CancelButton
          onClick={onClose}
          disabled={isSaving}
          {...getTestProps(testId, 'cancel', 'testId')}
        />
        <SaveButton
          form={formId}
          isSaving={isSaving}
          {...getTestProps(testId, 'save', 'testId')}
        />
      </div>
    </>
  );
};

export default EditObjectContentModal;

EditObjectContentModal.propTypes = {
  /**
   * Content object type name
   */
  contentTypeName: PropTypes.string.isRequired,
  /**
   * Content object id
   */
  id: PropTypes.string,
  /**
   * Edit modal test id
   */
  testId: PropTypes.string,
  /**
   * Should the form have switches for enabling fields
   */
  isPatchable: PropTypes.bool,
};

EditObjectContentModal.defaultProps = {
  testId: '',
};

import { useContext, useEffect, useMemo } from 'react';
import { useStore } from '@tanstack/react-form';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';
import { useFormContext } from '../form';

const DirtyHandler = () => {
  const form = useFormContext();

  const { isDirty, isSubmitting, isTouched } = useStore(
    form.store,
    (state) => ({
      isDirty: state.isDirty,
      isSubmitting: state.isSubmitting,
      isTouched: state.isTouched,
    }),
  );

  const { setDirty } = useContext(DirtyHandlerContext);

  const block = useMemo(
    () => isDirty && !isSubmitting && isTouched,
    [isSubmitting, isTouched, isDirty],
  );

  useEffect(() => {
    setDirty(block);
    return () => {
      setDirty(false);
    };
  }, [block, setDirty]);
};

export default DirtyHandler;

DirtyHandler.propTypes = {};

DirtyHandler.defaultProps = {};

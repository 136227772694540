// graphics
import { ReactComponent as ZoomMaleWorkingImage } from './graphics/male-working.svg';
import { ReactComponent as ListDocumentImage } from './graphics/list-document.svg';
import { ReactComponent as PricingImage } from './graphics/pricing.svg';
import { ReactComponent as PeopleWithHeartImage } from './graphics/people-with-heart.svg';

// alerts
import { ReactComponent as WarningIcon } from './icons-library/alerts/exclamation_circled-icon.svg';
import { ReactComponent as WarningTriangleIcon } from './icons-library/alerts/exclamation_triangle-icon.svg';
import { ReactComponent as WarningTriangleRedIcon } from './icons-library/alerts/exclamation_triangle_red-icon.svg';

// arrows
import { ReactComponent as ArrowLeftIcon } from './icons-library/arrows/arrow_left-icon.svg';
import { ReactComponent as ArrowLeftThinIcon } from './icons-library/arrows/arrow_left_thin-icon.svg';
import { ReactComponent as ArrowLeftCurvedIcon } from './icons-library/arrows/arrow_left_curved-icon.svg';
import { ReactComponent as ArrowRightCurvedIcon } from './icons-library/arrows/arrow_right_curved-icon.svg';
import { ReactComponent as ArrowRightCurvedThinIcon } from './icons-library/arrows/arrow_right_curved_thin-icon.svg';
import { ReactComponent as ArrowUpRightIcon } from './icons-library/arrows/arrow_up_right-icon.svg';
import { ReactComponent as ArrowRightIcon } from './icons-library/arrows/arrow_right-icon.svg';
import { ReactComponent as ArrowRightThinIcon } from './icons-library/arrows/arrow_right_thin-icon.svg';
import { ReactComponent as ArrowUpIcon } from './icons-library/arrows/arrow_up-icon.svg';
import { ReactComponent as ArrowUpThinIcon } from './icons-library/arrows/arrow_up_thin-icon.svg';
import { ReactComponent as ArrowLeftRightIcon } from './icons-library/arrows/arrow_left_right-icon.svg';
import { ReactComponent as ArrowCollapseLeftIcon } from './icons-library/arrows/arrow_collapse_left-icon.svg';
import { ReactComponent as ArrowCollapseRightIcon } from './icons-library/arrows/arrow_collapse_right-icon.svg';

//carets
import { ReactComponent as CaretLeftStopIcon } from './icons-library/carets/caret_left_stop-icon.svg';
import { ReactComponent as CaretLeftIcon } from './icons-library/carets/caret_left-icon.svg';
import { ReactComponent as CaretRightStopIcon } from './icons-library/carets/caret_right_stop-icon.svg';
import { ReactComponent as CaretRightIcon } from './icons-library/carets/caret_right-icon.svg';
import { ReactComponent as CaretUpIcon } from './icons-library/carets/caret_up-icon.svg';
import { ReactComponent as CaretDownIcon } from './icons-library/carets/caret_down-icon.svg';

// Colorful misc
import { ReactComponent as KeyColorIcon } from './icons-library/color/key-icon.svg';
import { ReactComponent as KeyColorIconWhite } from './icons-library/color/key-icon_white.svg';
import { ReactComponent as ClipboardColorIcon } from './icons-library/color/clipboard-icon.svg';
import { ReactComponent as ClipboardColorIconWhite } from './icons-library/color/clipboard-icon_white.svg';
import { ReactComponent as CopyColorIcon } from './icons-library/color/copy-icon.svg';
import { ReactComponent as CopyColorIconWhite } from './icons-library/color/copy-icon_white.svg';
import { ReactComponent as FourSquaresColor } from './icons-library/color/four_squares-icon.svg';
import { ReactComponent as FourSquaresColorWhite } from './icons-library/color/four_squares-icon_white.svg';
import { ReactComponent as GearColorIcon } from './icons-library/color/gear-icon.svg';
import { ReactComponent as GearColorIconWhite } from './icons-library/color/gear-icon_white.svg';
import { ReactComponent as MediaColorIcon } from './icons-library/color/media-icon.svg';
import { ReactComponent as MediaColorIconWhite } from './icons-library/color/media-icon_white.svg';
import { ReactComponent as TagsColorIcon } from './icons-library/color/tags-icon.svg';
import { ReactComponent as TagsColorIconWhite } from './icons-library/color/tags-icon_white.svg';
import { ReactComponent as MenuIcon } from './icons-library/color/menu-icon.svg';
import { ReactComponent as MenuIconWhite } from './icons-library/color/menu-icon-white.svg';
import { ReactComponent as PuzzleColorIcon } from './icons-library/color/puzzle-icon.svg';
import { ReactComponent as PuzzleColorIconWhite } from './icons-library/color/puzzle-icon_white.svg';
import { ReactComponent as LifebuoyColorIcon } from './icons-library/color/lifebuoy-icon.svg';
import { ReactComponent as LifebuoyColorIconWhite } from './icons-library/color/lifebuoy-icon_white.svg';
import { ReactComponent as LightningColorIcon } from './icons-library/color/lightning-icon.svg';
import { ReactComponent as LightningColorIconWhite } from './icons-library/color/lightning-icon_white.svg';
import { ReactComponent as LightningCurrentColorIcon } from './icons-library/color/lightning-current-color-icon.svg';
import { ReactComponent as UsersIcon } from './icons-library/color/users-icon.svg';
import { ReactComponent as UsersIconWhite } from './icons-library/color/users-icon_white.svg';
import { ReactComponent as UsersCurrentColorIcon } from './icons-library/color/users-current-color-icon.svg';
import { ReactComponent as ConnectedDotsColorIcon } from './icons-library/color/connected_dots-icon.svg';
import { ReactComponent as ConnectedDotsColorIconWhite } from './icons-library/color/connected_dots-icon_white.svg';
import { ReactComponent as QrCodeIcon } from './icons-library/color/qr-icon.svg';
import { ReactComponent as UsersKeyIcon } from './icons-library/color/users-key-icon.svg';
import { ReactComponent as UsersKeyIconWhite } from './icons-library/color/users-key-icon_white.svg';
import { ReactComponent as FolderIcon } from './icons-library/color/folder-icon.svg';
import { ReactComponent as FolderIconWhite } from './icons-library/color/folder-icon_white.svg';
import { ReactComponent as CardPaymentIcon } from './icons-library/color/card_payment-icon.svg';
import { ReactComponent as CardPaymentIconWhite } from './icons-library/color/card_payment-icon_white.svg';

// Contact
import { ReactComponent as EnvelopeIcon } from './icons-library/contact/envelope-icon.svg';

// controls
import { ReactComponent as RefreshIcon } from './icons-library/controls/refresh-icon.svg';
import { ReactComponent as ReloadIcon } from './icons-library/controls/reload-icon.svg';
import { ReactComponent as PlayCircleIcon } from './icons-library/controls/play_circle-icon.svg';
import { ReactComponent as CloseIcon } from './icons-library/controls/close-icon.svg';
import { ReactComponent as ListViewIcon } from './icons-library/controls/list-view-icon.svg';
import { ReactComponent as TilesViewIcon } from './icons-library/controls/tiles-view-icon.svg';
import { ReactComponent as DownloadCircleIcon } from './icons-library/controls/download-icon.svg';
import { ReactComponent as CenterIcon } from './icons-library/controls/center-icon.svg';
import { ReactComponent as MaximizeIcon } from './icons-library/controls/maximize-icon.svg';
import { ReactComponent as AddVariantIcon } from './icons-library/controls/add-variant-icon.svg';
import { ReactComponent as DragIcon } from './icons-library/controls/drag-icon.svg';
import { ReactComponent as UpgradeIcon } from './icons-library/controls/upgrade-icon.svg';
import { ReactComponent as BoxDownloadIcon } from './icons-library/controls/box_download-icon.svg';
import { ReactComponent as LeaveIcon } from './icons-library/controls/leave-icon.svg';

// Flags
import { ReactComponent as PolandFlagIcon } from './icons-library/country-flags/poland_flag-icon.svg';
import { ReactComponent as UnitedKingdomFlagIcon } from './icons-library/country-flags/united-kingdom_flag-icon.svg';

// Essentials
import { ReactComponent as BookIcon } from './icons-library/essentials/book-icon.svg';
import { ReactComponent as CalendarIcon } from './icons-library/essentials/calendar-icon.svg';
import { ReactComponent as CalendarIconBlue } from './icons-library/essentials/calendar-icon_blue.svg';
import { ReactComponent as ClipboardIcon } from './icons-library/essentials/clipboard-icon.svg';
import { ReactComponent as CodeIcon } from './icons-library/essentials/code-icon.svg';
import { ReactComponent as CopyIcon } from './icons-library/essentials/copy-icon.svg';
import { ReactComponent as DuplicateIcon } from './icons-library/essentials/duplicate-icon.svg';
import { ReactComponent as DuplicateSolidIcon } from './icons-library/essentials/duplicate_solid-icon.svg';
import { ReactComponent as DocumentTextIcon } from './icons-library/essentials/document-icon.svg';
import { ReactComponent as ClockIcon } from './icons-library/essentials/clock-icon.svg';
import { ReactComponent as ClockIconBlue } from './icons-library/essentials/clock-icon_blue.svg';
import { ReactComponent as FileIcon } from './icons-library/essentials/file-icon.svg';
import { ReactComponent as FolderTabIcon } from './icons-library/essentials/folder-tab-icon.svg';
import { ReactComponent as StarIcon } from './icons-library/essentials/star-icon.svg';
import { ReactComponent as StarFavIcon } from './icons-library/essentials/favorite-start-icon.svg';
import { ReactComponent as HouseIcon } from './icons-library/essentials/house-icon.svg';
import { ReactComponent as FloppyDiskIcon } from './icons-library/essentials/floppy_disk-icon.svg';
import { ReactComponent as BoxWithArrowIcon } from './icons-library/essentials/box_with_arrow-icon.svg';
import { ReactComponent as WorldIcon } from './icons-library/essentials/world-icon.svg';

// Human
import { ReactComponent as EyeCrossedIcon } from './icons-library/human/eye_crossed-icon.svg';
import { ReactComponent as EyeIcon } from './icons-library/human/eye-icon.svg';
import { ReactComponent as AvatarIcon } from './icons-library/human/user-icon.svg';

// internet
import { ReactComponent as DownloadIcon } from './icons-library/internet/download-icon.svg';
import { ReactComponent as LinkIcon } from './icons-library/internet/link-icon.svg';
import { ReactComponent as LogOutIcon } from './icons-library/internet/log_out-icon.svg';

// Programming languages
import { ReactComponent as AngularLogo } from './icons-library/programming-languages/angular-icon.svg';
import { ReactComponent as CSharpLogo } from './icons-library/programming-languages/csharp-icon.svg';
import { ReactComponent as GoLogo } from './icons-library/programming-languages/go-icon.svg';
import { ReactComponent as JavaLogo } from './icons-library/programming-languages/java-icon.svg';
import { ReactComponent as NodeJsLogo } from './icons-library/programming-languages/nodejs-icon.svg';
import { ReactComponent as PHPLogo } from './icons-library/programming-languages/php-icon.svg';
import { ReactComponent as PostmanLogo } from './icons-library/programming-languages/postman-icon.svg';
import { ReactComponent as PythonLogo } from './icons-library/programming-languages/python-icon.svg';
import { ReactComponent as GatsbyLogo } from './icons-library/programming-languages/gatsby-icon.svg';

// SocialMedia
import { ReactComponent as DiscordColorIcon } from './icons-library/social-media/color/discord-icon.svg';
import { ReactComponent as FacebookColorIcon } from './icons-library/social-media/color/facebook-icon.svg';
import { ReactComponent as GithubColorIcon } from './icons-library/social-media/color/github-icon.svg';
import { ReactComponent as GithubColorIconWhite } from './icons-library/social-media/color/github-icon_white.svg';
import { ReactComponent as LinkedinColorIcon } from './icons-library/social-media/color/linkedin-icon.svg';
import { ReactComponent as RedditColorIcon } from './icons-library/social-media/color/reddit-icon.svg';
import { ReactComponent as RedditColorIconWhite } from './icons-library/social-media/color/reddit-icon_white.svg';
import { ReactComponent as TwitterColorIcon } from './icons-library/social-media/color/twitter-icon.svg';
import { ReactComponent as YoutubeColorIcon } from './icons-library/social-media/color/youtube-icon.svg';
import { ReactComponent as DiscordWhiteIcon } from './icons-library/social-media/white/discord_white-icon.svg';
import { ReactComponent as FacebookWhiteIcon } from './icons-library/social-media/white/facebook_white-icon.svg';
import { ReactComponent as GithubWhiteIcon } from './icons-library/social-media/white/github_white-icon.svg';
import { ReactComponent as LinkedinWhiteIcon } from './icons-library/social-media/white/linkedin_white-icon.svg';
import { ReactComponent as TwitterWhiteIcon } from './icons-library/social-media/white/twitter_white-icon.svg';
import { ReactComponent as WindowsWhiteLogo } from './icons-library/social-media/white/windows_white-icon.svg';
import { ReactComponent as YoutubeWhiteIcon } from './icons-library/social-media/white/youtube_white-icon.svg';
import { ReactComponent as GoogleWhiteIcon } from './icons-library/social-media/white/google_white-icon.svg';

// symbols
import { ReactComponent as CheckCircleIcon } from './icons-library/symbols/checkbox_circle-icon.svg';
import { ReactComponent as DotIcon } from './icons-library/symbols/dot-icon.svg';
import { ReactComponent as CheckmarkIcon } from './icons-library/symbols/checkmark-icon.svg';
import { ReactComponent as CheckmarkWhiteIcon } from './icons-library/symbols/checkmark_white-icon.svg';
import { ReactComponent as PlusIcon } from './icons-library/symbols/plus-icon.svg';
import { ReactComponent as PlusSolidIcon } from './icons-library/symbols/plus_solid-icon.svg';
import { ReactComponent as PlusCircleOutlineIcon } from './icons-library/symbols/plus_outline-icon.svg';
import { ReactComponent as PlusDoubleCircleIcon } from './icons-library/symbols/plus_double_circle-icon.svg';
import { ReactComponent as UnionIcon } from './icons-library/symbols/union-icon.svg';
import { ReactComponent as CrownIcon } from './icons-library/symbols/crown-icon.svg';
import { ReactComponent as MinusIcon } from './icons-library/symbols/minus-icon.svg';

// typography
import { ReactComponent as EllipsisIcon } from './icons-library/typography/ellipsis-icon.svg';
import { ReactComponent as EllipsisVerticalIcon } from './icons-library/typography/ellipsis_vertical-icon.svg';
import { ReactComponent as PencilSquareIcon } from './icons-library/typography/pencil_square-icon.svg';
import { ReactComponent as PencilIcon } from './icons-library/typography/pencil-icon.svg';
import { ReactComponent as PencilSolidIcon } from './icons-library/typography/pencil_solid-icon.svg';
import { ReactComponent as QuestionMarkCircleIcon } from './icons-library/typography/question_mark_circle-icon.svg';
import { ReactComponent as QuestionMarkWavedIcon } from './icons-library/typography/question_mark_waved-icon.svg';
import { ReactComponent as QuestionMarkIcon } from './icons-library/typography/question_mark-icon.svg';
import { ReactComponent as InformationCircleIcon } from './icons-library/typography/information_circle-icon.svg';
import { ReactComponent as InformationSignIcon } from './icons-library/typography/information_sign-icon.svg';
import { ReactComponent as TagIcon } from './icons-library/typography/tag-icon.svg';
import { ReactComponent as TagPlusIcon } from './icons-library/typography/tag-plus-icon.svg';

// utilities
import { ReactComponent as AddBackgroundIcon } from './icons-library/utilities/add_background_image-icon.svg';
import { ReactComponent as AddBorderIcon } from './icons-library/utilities/add_border-icon.svg';
import { ReactComponent as TrashIcon } from './icons-library/utilities/trash_thin-icon.svg';
import { ReactComponent as TrashSolidIcon } from './icons-library/utilities/trash-icon.svg';
import { ReactComponent as ComputerDesktopIcon } from './icons-library/utilities/computer_desktop-icon.svg';
import { ReactComponent as MagnifierIcon } from './icons-library/utilities/magnifier-icon.svg';
import { ReactComponent as GearIcon } from './icons-library/utilities/gear-icon.svg';
import { ReactComponent as ReturnOldFront } from './icons-library/utilities/return_old_front.svg';
import { ReactComponent as ReturnOldFrontWhite } from './icons-library/utilities/return_old_front_white.svg';

// weather
import { ReactComponent as LightningIcon } from './icons-library/weather/lightning-icon.svg';
import { ReactComponent as SunIcon } from './icons-library/weather/sun-icon.svg';
import { ReactComponent as MoonIcon } from './icons-library/weather/moon-icon.svg';

// loaders
import { ReactComponent as SpinnerGridIcon } from './loaders/loader-grid.svg';
import { ReactComponent as SpinnerWhiteIcon } from './loaders/loader-white.svg';
import { ReactComponent as SpinnerIcon } from './loaders/loader.svg';

// logotypes
import { ReactComponent as AppSumoLogo } from './logotypes/appsumo-logo.svg';
import { ReactComponent as FlotiqLogo } from './logotypes/flotiq-logo.svg';
import { ReactComponent as FlotiqLogoWhite } from './logotypes/flotiq_white-logo.svg';
import { ReactComponent as FlotiqBadgeLogo } from './logotypes/flotiq_badge-logo.svg';

// ctd
import { ReactComponent as BlockIcon } from './ctd/block-icon.svg';
import { ReactComponent as CheckboxIcon } from './ctd/checkbox-icon.svg';
import { ReactComponent as DateIcon } from './ctd/date-icon.svg';
import { ReactComponent as EmailIcon } from './ctd/email-icon.svg';
import { ReactComponent as GeoIcon } from './ctd/geo-icon.svg';
import { ReactComponent as ListIcon } from './ctd/list-icon.svg';
import { ReactComponent as MarkdownIcon } from './ctd/markdown-icon.svg';
import { ReactComponent as MediaIcon } from './ctd/media-icon.svg';
import { ReactComponent as NumberIcon } from './ctd/number-icon.svg';
import { ReactComponent as RadioIcon } from './ctd/radio-icon.svg';
import { ReactComponent as RelationIcon } from './ctd/relation-icon.svg';
import { ReactComponent as RichTextIcon } from './ctd/rich-text.svg';
import { ReactComponent as SelectIcon } from './ctd/select-icon.svg';
import { ReactComponent as SimpleListIcon } from './ctd/simple-list-icon.svg';
import { ReactComponent as TextIcon } from './ctd/text-icon.svg';
import { ReactComponent as TextareaIcon } from './ctd/textarea-icon.svg';

// framework
import { ReactComponent as GatsbyIcon } from './icons-library/framework/gatsby.svg';
import { ReactComponent as NextJSIcon } from './icons-library/framework/nextjs.svg';

export {
  // graphics
  ZoomMaleWorkingImage,
  ListDocumentImage,
  PricingImage,
  PeopleWithHeartImage,

  // alerts
  WarningIcon,
  WarningTriangleIcon,
  WarningTriangleRedIcon,

  // arrows
  ArrowLeftIcon,
  ArrowLeftThinIcon,
  ArrowLeftCurvedIcon,
  ArrowRightCurvedIcon,
  ArrowRightCurvedThinIcon,
  ArrowUpRightIcon,
  ArrowRightIcon,
  ArrowRightThinIcon,
  ArrowUpIcon,
  ArrowUpThinIcon,
  ArrowLeftRightIcon,
  ArrowCollapseLeftIcon,
  ArrowCollapseRightIcon,

  //carets
  CaretLeftStopIcon,
  CaretLeftIcon,
  CaretRightStopIcon,
  CaretRightIcon,
  CaretUpIcon,
  CaretDownIcon,

  // Colorful misc
  ClipboardColorIcon,
  ClipboardColorIconWhite,
  ConnectedDotsColorIcon,
  ConnectedDotsColorIconWhite,
  CopyColorIcon,
  CopyColorIconWhite,
  FourSquaresColor,
  FourSquaresColorWhite,
  GearColorIcon,
  GearColorIconWhite,
  LifebuoyColorIcon,
  LifebuoyColorIconWhite,
  LightningColorIcon,
  LightningColorIconWhite,
  LightningCurrentColorIcon,
  KeyColorIcon,
  KeyColorIconWhite,
  MediaColorIcon,
  MediaColorIconWhite,
  TagsColorIcon,
  TagsColorIconWhite,
  MenuIcon,
  MenuIconWhite,
  PuzzleColorIcon,
  PuzzleColorIconWhite,
  QrCodeIcon,
  UsersCurrentColorIcon,
  UsersIcon,
  UsersIconWhite,
  UsersKeyIcon,
  UsersKeyIconWhite,
  FolderIcon,
  FolderIconWhite,
  CardPaymentIcon,
  CardPaymentIconWhite,

  // Contacts
  EnvelopeIcon,

  // controls
  RefreshIcon,
  ReloadIcon,
  PlayCircleIcon,
  CloseIcon,
  ListViewIcon,
  TilesViewIcon,
  DownloadCircleIcon,
  CenterIcon,
  MaximizeIcon,
  AddVariantIcon,
  DragIcon,
  UpgradeIcon,
  BoxDownloadIcon,
  LeaveIcon,

  // Flags
  PolandFlagIcon,
  UnitedKingdomFlagIcon,

  // Essentials
  BookIcon,
  CalendarIcon,
  CalendarIconBlue,
  ClipboardIcon,
  CodeIcon,
  CopyIcon,
  DuplicateIcon,
  DuplicateSolidIcon,
  DocumentTextIcon,
  ClockIcon,
  ClockIconBlue,
  FileIcon,
  FolderTabIcon,
  HouseIcon,
  StarIcon,
  StarFavIcon,

  // Human
  EyeCrossedIcon,
  EyeIcon,
  AvatarIcon,

  // internet
  DownloadIcon,
  LinkIcon,
  LogOutIcon,

  // Programming languages
  AngularLogo,
  CSharpLogo,
  GoLogo,
  JavaLogo,
  NodeJsLogo,
  PHPLogo,
  PostmanLogo,
  PythonLogo,
  GatsbyLogo,

  // SocialMedia
  DiscordColorIcon,
  FacebookColorIcon,
  GithubColorIcon,
  GithubColorIconWhite,
  LinkedinColorIcon,
  RedditColorIcon,
  RedditColorIconWhite,
  TwitterColorIcon,
  YoutubeColorIcon,
  DiscordWhiteIcon,
  FacebookWhiteIcon,
  GithubWhiteIcon,
  LinkedinWhiteIcon,
  TwitterWhiteIcon,
  WindowsWhiteLogo,
  YoutubeWhiteIcon,
  GoogleWhiteIcon,

  // symbols
  CheckCircleIcon,
  DotIcon,
  CheckmarkIcon,
  CheckmarkWhiteIcon,
  PlusIcon,
  PlusSolidIcon,
  PlusCircleOutlineIcon,
  PlusDoubleCircleIcon,
  UnionIcon,
  CrownIcon,
  MinusIcon,
  FloppyDiskIcon,
  BoxWithArrowIcon,
  WorldIcon,

  // typography
  EllipsisIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  PencilIcon,
  PencilSolidIcon,
  QuestionMarkCircleIcon,
  QuestionMarkWavedIcon,
  QuestionMarkIcon,
  InformationCircleIcon,
  InformationSignIcon,
  TagIcon,
  TagPlusIcon,

  // utilities
  AddBackgroundIcon,
  AddBorderIcon,
  TrashIcon,
  TrashSolidIcon,
  ComputerDesktopIcon,
  MagnifierIcon,
  GearIcon,
  ReturnOldFront,
  ReturnOldFrontWhite,

  // weather
  LightningIcon,
  SunIcon,
  MoonIcon,

  // loaders
  SpinnerGridIcon,
  SpinnerWhiteIcon,
  SpinnerIcon,

  // logotypes
  AppSumoLogo,
  FlotiqLogo,
  FlotiqLogoWhite,
  FlotiqBadgeLogo,

  // ctd
  BlockIcon,
  CheckboxIcon,
  DateIcon,
  EmailIcon,
  GeoIcon,
  ListIcon,
  MarkdownIcon,
  MediaIcon,
  NumberIcon,
  RadioIcon,
  RelationIcon,
  RichTextIcon,
  SelectIcon,
  SimpleListIcon,
  TextIcon,
  TextareaIcon,

  // framework
  GatsbyIcon,
  NextJSIcon,
};

// Aliases:

export const DeleteIcon = TrashIcon;

import { createFormHook, createFormHookContexts } from '@tanstack/react-form';

import DirtyHandler from './form-components/DirtyHandler';
import ValidationToastHandler from './form-components/ValidationToastHandler';

import InputField from './fields/Input/InputField';
import TextareaField from './fields/Textarea/TextareaField';
import PermissionsField from './fields/PermissionsField/PermissionsField';
import PermissionField from './fields/PermissionField/PermissionField';
import FieldPermissions from './fields/FieldPermissions/FieldPermisssions';
import CheckboxField from './fields/Checkbox/CheckboxField';
import PermissionContentType from './fields/PermissionContentType/PermissionContentType';

export const { fieldContext, formContext, useFieldContext, useFormContext } =
  createFormHookContexts();

export const { useAppForm, withForm } = createFormHook({
  fieldComponents: {
    PermissionsField,
    PermissionField,
    FieldPermissions,
    PermissionContentType,
    Input: InputField,
    Textarea: TextareaField,
    Checkbox: CheckboxField,
  },
  formComponents: { DirtyHandler, ValidationToastHandler },
  fieldContext,
  formContext,
});

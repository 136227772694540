import { useFieldContext } from '../../form';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useCallback } from 'react';
import { useFieldErros } from '../../useFieldErrors';

const CheckboxField = ({ indeterminate, ...props }) => {
  const field = useFieldContext();
  const errors = useFieldErros();

  const onChange = useCallback(
    (e) => {
      if (indeterminate) {
        field.handleChange(true);
        return true;
      } else field.handleChange(e.target.checked);
    },
    [field, indeterminate],
  );

  return (
    <Checkbox
      {...props}
      name={field.name}
      checked={field.state.value}
      onChange={onChange}
      onBlur={field.handleBlur}
      indeterminate={indeterminate}
      error={errors}
    />
  );
};

export default CheckboxField;

CheckboxField.propTypes = Checkbox.propTypes;

CheckboxField.defaultProps = Checkbox.defaultProps;

export const transformValues = (values) => ({
  description: values.description,
  name: values.name,
  permissions: values.permissions.map((permission) => ({
    ...permission,
    contentTypeDefinition: permission.contentTypeDefinition?.id
      ? { id: permission.contentTypeDefinition.id }
      : null,
    attributesPermission: transformAttributes(
      permission.type,
      permission.attributesPermission,
      permission.contentTypeDefinition?.metaDefinition?.order,
    ),
  })),
});

const transformAttributes = (type, attributesPermission, order) => {
  if (type === 'CTD' || !attributesPermission) return;

  let hasAnyAtribbute = false;

  const newAttributes = Object.entries(attributesPermission || {}).reduce(
    (acc, [key, value]) => {
      if (!value?.length || value.length === order.length) {
        acc[key] = undefined;
      } else {
        hasAnyAtribbute = true;
        acc[key] = value;
      }
      return acc;
    },
    {},
  );

  return hasAnyAtribbute ? newAttributes : undefined;
};

export class RolePermissions {
  static PERMISSIONS_TYPES = {
    READ: 'canRead',
    CREATE: 'canCreate',
    DELETE: 'canDelete',
    UPDATE: 'canUpdate',
  };

  constructor(userRoles) {
    this.userRoles = userRoles;

    if (userRoles?.length > 0) {
      let currentPermissions = { CO: {}, CTD: {} };

      userRoles.forEach((permission) => {
        const type = permission.type;
        const ctdName =
          permission.ctdName || permission.contentTypeDefinition?.name || '*';

        if (!currentPermissions[type][ctdName]) {
          currentPermissions[type][ctdName] = {
            canCreate: permission.canCreate,
            canDelete: permission.canDelete,
            canRead: permission.canRead,
            canUpdate: permission.canUpdate,
          };
        } else {
          ['canCreate', 'canDelete', 'canRead', 'canUpdate'].forEach((can) => {
            if (!currentPermissions[type][ctdName][can] && permission[can]) {
              currentPermissions[type][ctdName][can] = true;
            }
          });
        }

        ['canCreate', 'canDelete', 'canRead', 'canUpdate'].forEach((can) => {
          const isOverriden = userRoles.find(
            (permission) =>
              permission.type === 'CO' &&
              permission.contentTypeDefinition?.name === ctdName &&
              permission[can] &&
              !permission.attributesPermission?.[can],
          );

          if (!isOverriden && permission.attributesPermission?.[can]) {
            permission.attributesPermission[can].forEach((field) => {
              if (!currentPermissions[type][ctdName].fields) {
                currentPermissions[type][ctdName].fields = {};
              }
              if (!currentPermissions[type][ctdName].fields[can]) {
                currentPermissions[type][ctdName].fields[can] = {};
              }
              currentPermissions[type][ctdName].fields[can][field] = true;
            });
          }
        });

        if (permission.canRead) {
          if (type === 'CTD') this.visibleCTDs = true;
          else this.visibleCOs = true;
        }
      });

      this.permissions = currentPermissions;
    }
  }

  #checkProperty(
    ctdName = '*',
    permission = RolePermissions.PERMISSIONS_TYPES.READ,
    type = 'CO',
    field = null,
  ) {
    if (!this.permissions) return true;

    if (
      field &&
      typeof this.permissions[type]?.[ctdName]?.fields?.[permission] !==
        'undefined'
    ) {
      return !!this.permissions[type]?.[ctdName]?.fields?.[permission]?.[field];
    }

    return (
      !!this.permissions[type]?.['*']?.[permission] ||
      !!this.permissions[type]?.[ctdName]?.[permission]
    );
  }

  #checkProperties(ctdName = '*', type = 'CO', field = null) {
    return {
      canCreate: this.#checkProperty(
        ctdName,
        RolePermissions.PERMISSIONS_TYPES.CREATE,
        type,
        field,
      ),
      canDelete: this.#checkProperty(
        ctdName,
        RolePermissions.PERMISSIONS_TYPES.DELETE,
        type,
        field,
      ),
      canRead: this.#checkProperty(
        ctdName,
        RolePermissions.PERMISSIONS_TYPES.READ,
        type,
        field,
      ),
      canUpdate: this.#checkProperty(
        ctdName,
        RolePermissions.PERMISSIONS_TYPES.UPDATE,
        type,
        field,
      ),
    };
  }

  canCtd(ctdName, permission = RolePermissions.PERMISSIONS_TYPES.READ) {
    if (!this.userRoles) return false;

    return this.#checkProperty(ctdName, permission, 'CTD');
  }

  canCo(
    ctdName,
    permission = RolePermissions.PERMISSIONS_TYPES.READ,
    field = null,
  ) {
    if (!this.userRoles) return false;

    return this.#checkProperty(ctdName, permission, 'CO', field);
  }

  hasCoLimitedFields(ctdName) {
    if (!this.userRoles) return true;
    return typeof this.permissions?.['CO']?.[ctdName]?.fields !== 'undefined';
  }

  getCtdPermissions(ctdName) {
    if (!this.userRoles)
      return {
        canCreate: false,
        canDelete: false,
        canRead: false,
        canUpdate: false,
      };

    return this.#checkProperties(ctdName, 'CTD');
  }

  getCoPermissions(ctdName, field = null) {
    if (!this.userRoles)
      return {
        canCreate: false,
        canDelete: false,
        canRead: false,
        canUpdate: false,
      };

    return this.#checkProperties(ctdName, 'CO', field);
  }

  ctdsVisible() {
    if (!this.userRoles) return false;

    if (!this.permissions) return true;

    return !!this.visibleCTDs;
  }

  cosVisible() {
    if (!this.userRoles) return false;

    if (!this.permissions) return true;

    return !!this.visibleCOs;
  }
}

import { useCallback } from 'react';
import { useStore } from '@tanstack/react-form';
import PropTypes from 'prop-types';
import { useFieldContext, useFormContext } from '../../form';
import { deepReadKeyValue } from '../../../lib/helpers';
import Dropdown from '../../../components/Dropdown/Dropdown';

const PermissionContentType = ({
  permissionFieldName,
  ctdsOptions,
  ruleColumn,
  disabled,
  placeholder,
  testId,
}) => {
  const form = useFormContext();
  const field = useFieldContext();

  const permissionType = useStore(form.store, (state) =>
    deepReadKeyValue(`${permissionFieldName}.type`, state.values),
  );

  const updateAttributePermissions = useCallback(
    (newOrder, oldOrder) => {
      if (!newOrder) {
        form.setFieldValue(
          `${permissionFieldName}.attributesPermission`,
          undefined,
        );
        return;
      }

      ruleColumn.forEach(({ name, isFieldPermission }) => {
        if (!isFieldPermission) return;
        if (!oldOrder && newOrder) {
          form.setFieldValue(`${permissionFieldName}.${name}`, false);
        } else {
          form.setFieldValue(
            `${permissionFieldName}.attributesPermission.${name}`,
            (prev) => {
              if (!prev) return;
              if (prev?.length === oldOrder?.length) return newOrder;
              return (prev || []).filter((property) =>
                newOrder.includes(property),
              );
            },
          );
        }
      });
    },
    [form, permissionFieldName, ruleColumn],
  );

  const onChange = useCallback(
    (e) => {
      if (permissionType === 'CO') {
        const newOrder = e.option?.metaDefinition?.order;
        const oldOrder = field.state.value?.metaDefinition?.order;
        updateAttributePermissions(newOrder, oldOrder);
      }

      field.handleChange({
        id: e.target.value,
        metaDefinition: e.option?.metaDefinition,
        required: e.option?.schemaDefinition?.required,
      });
    },
    [field, permissionType, updateAttributePermissions],
  );

  return (
    <Dropdown
      name={field.name}
      value={field.state.value?.id}
      onChange={onChange}
      onBlur={field.handleBlur}
      options={ctdsOptions}
      debounceTime={150}
      placeholder={placeholder}
      disabled={disabled}
      additionalClasses="min-w-0 grow"
      nullable
      testId={testId}
    />
  );
};

export default PermissionContentType;

PermissionContentType.propTypes = {
  /**
   * Name of main field for the permission, eg. permission[0]
   */
  permissionFieldName: PropTypes.string.isRequired,
  /**
   * Permission available rules
   */
  ruleColumn: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  /**
   * Content type options
   */
  ctdsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.arrayOf(PropTypes.any),
      ]),
    }),
  ),
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Content types dropdown placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Test id for field
   */
  testId: PropTypes.string,
};

PermissionContentType.defaultProps = {
  ruleColumn: [],
  ctdsOptions: [],
  disabled: false,
  placeholder: '',
  testId: '',
};

import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Disclosure, Transition } from '@headlessui/react';
import FieldRuleCheckbox from './FieldRuleCheckbox/FieldRuleCheckbox';
import { CaretDownIcon } from '../../../images/shapes';
import { getTestProps } from '../../../lib/helpers';
import RequiredTemplate from '../../../components/RequiredTemplate/RequiredTemplate';

const FieldPermissions = ({
  ruleColumn,
  contentTypeDefinition,
  disabled,
  testId,
}) => {
  const { t } = useTranslation();

  if (!contentTypeDefinition?.metaDefinition) return;

  return (
    <Disclosure
      className="w-full text-sm dark:text-white col-span-2"
      defaultOpen={false}
    >
      {({ open }) => (
        <div className="rounded-lg bg-blue-50 dark:bg-slate-800/70 relative">
          <Disclosure.Button
            className="flex w-full items-center justify-between cursor-pointer uppercase
                      p-4 border-b border-white dark:border-slate-950 dark:text-slate-400"
            {...getTestProps(testId, 'panel-open')}
          >
            {t('UserRoles.FieldsPermissions')}
            <CaretDownIcon
              className={twMerge(
                'w-3 transition-transform duration-300 ease-in-out',
                open && 'rotate-180',
              )}
            />
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition-all duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition-all duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="py-1.5 grid grid-cols-1 sm:grid-cols-2 gap-y-2 items-center">
              {(contentTypeDefinition.metaDefinition.order || []).map(
                (propertyKey) => (
                  <Fragment key={`${propertyKey}-field-rules`}>
                    <span className="px-4">
                      {contentTypeDefinition.metaDefinition.propertiesConfig?.[
                        propertyKey
                      ]?.label || propertyKey}

                      {contentTypeDefinition.required?.includes(
                        propertyKey,
                      ) && <RequiredTemplate />}
                    </span>

                    <div className="grid grid-cols-4 py-3">
                      <div className="col-span-3 grid grid-cols-3 justify-items-center">
                        {ruleColumn.map(({ name, isFieldPermission }) => {
                          if (!isFieldPermission) return null;
                          return (
                            <FieldRuleCheckbox
                              key={`${propertyKey}-${name}`}
                              ruleName={name}
                              propertyKey={propertyKey}
                              disabled={disabled}
                              {...getTestProps(
                                testId,
                                `${propertyKey}-${name}`,
                                'testId',
                              )}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Fragment>
                ),
              )}
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};

export default FieldPermissions;

FieldPermissions.propTypes = {
  /**
   * Permission available rules
   */
  ruleColumn: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  /**
   * Content type options
   */
  contentTypeDefinition: PropTypes.shape({
    metaDefinition: PropTypes.shape({
      order: PropTypes.arrayOf(PropTypes.string),
      propertiesConfig: PropTypes.shape({
        label: PropTypes.string,
      }),
    }),
  }),
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
};

FieldPermissions.defaultProps = {
  ruleColumn: [],
  disabled: false,
};

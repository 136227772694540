import { useFieldContext, useFormContext } from '../../form';
import PropTypes from 'prop-types';
import Button from '../../../components/Button/Button';
import { DeleteIcon } from '../../../images/shapes';
import GlobalRuleCheckbox from './GlobalRuleCheckbox/GlobalRuleCheckbox';
import { deepReadKeyValue, getTestProps } from '../../../lib/helpers';

const PermissionField = ({
  onRemove,
  ruleColumn,
  ctdsOptions,
  disabled,
  type,
  ctdPlaceholder,
  testId,
}) => {
  const form = useFormContext();
  const field = useFieldContext();

  return (
    <>
      <div className="w-full flex flex-row items-center">
        <form.AppField name={`${field.name}.contentTypeDefinition`}>
          {(subField) => (
            <subField.PermissionContentType
              ctdsOptions={ctdsOptions}
              placeholder={ctdPlaceholder}
              ruleColumn={ruleColumn}
              permissionFieldName={field.name}
              disabled={disabled}
              {...getTestProps(testId, 'content-type-definition', 'testId')}
            />
          )}
        </form.AppField>

        <Button
          onClick={onRemove}
          iconImage={<DeleteIcon className={'w-5 h-5 dark:text-gray-200'} />}
          buttonColor="borderless"
          iconPosition="top"
          disabled={disabled}
          additionalClasses={'p-3 pr-0 hover:opacity-50 justify-center w-fit'}
          type="button"
        />
      </div>

      <div className="w-full grid grid-cols-4 sm:hidden justify-items-center dark:text-slate-300">
        {ruleColumn.map(({ name, label }) => (
          <span key={name} className="uppercase text-sm">
            {label}
          </span>
        ))}
      </div>

      <div className="w-full grid grid-cols-4 justify-items-center">
        {ruleColumn.map(({ name }) => (
          <GlobalRuleCheckbox
            key={name}
            ruleName={name}
            disabled={disabled}
            {...getTestProps(testId, `global-${name}`, 'testId')}
          />
        ))}
      </div>

      <form.Subscribe
        selector={({ fieldMeta }) => {
          const isTouched = fieldMeta[field.name]?.isTouched;
          return isTouched
            ? Object.entries(fieldMeta)
                ?.filter(
                  ([key, { errors }]) =>
                    key.startsWith(field.name) && errors.length,
                )
                .map(([, { errors }]) => errors)
                .flat(2)
            : [];
        }}
      >
        {(errors) =>
          errors?.length > 0 ? (
            <span className="col-span-2 text-red text-sm mb-2 leading-none text-left">
              {errors
                .map((error) =>
                  typeof error === 'string' ? error : error.message,
                )
                .join(', ')}
            </span>
          ) : null
        }
      </form.Subscribe>

      {type === 'CO' && (
        <form.Subscribe
          selector={({ values }) =>
            deepReadKeyValue(`${field.name}.contentTypeDefinition`, values)
          }
        >
          {(contentTypeDefinition) =>
            contentTypeDefinition?.metaDefinition ? (
              <form.AppField name={`${field.name}.attributesPermission`}>
                {(subField) => (
                  <subField.FieldPermissions
                    contentTypeDefinition={contentTypeDefinition}
                    ruleColumn={ruleColumn}
                    disabled={disabled}
                    {...getTestProps(testId, `fields`, 'testId')}
                  />
                )}
              </form.AppField>
            ) : null
          }
        </form.Subscribe>
      )}
    </>
  );
};

export default PermissionField;

PermissionField.propTypes = {
  /**
   * On permission remove callback
   */
  onRemove: PropTypes.func.isRequired,
  /**
   * Permission available rules
   */
  ruleColumn: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  /**
   * Content type options
   */
  ctdsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.arrayOf(PropTypes.any),
      ]),
    }),
  ),
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Permission type
   */
  type: PropTypes.oneOf(['CTD', 'CO']),
  /**
   * Content types dropdown placeholder
   */
  ctdPlaceholder: PropTypes.string,
  /**
   * Test id for field
   */
  testId: PropTypes.string,
};

PermissionField.defaultProps = {
  ruleColumn: [],
  ctdsOptions: [],
  disabled: false,
  type: 'CTD',
  ctdPlaceholder: '',
  testId: '',
};

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getTestProps } from '../../../lib/helpers';
import Button from '../../../components/Button/Button';
import { useFieldContext, useFormContext } from '../../form';
import PermissionField from '../PermissionField/PermissionField';

const PermissionAppField = ({ index, ...props }) => {
  const form = useFormContext();
  const field = useFieldContext();

  const onRemove = useCallback(() => {
    field.removeValue(index);
  }, [field, index]);

  return (
    <form.AppField name={`${field.name}[${index}]`}>
      {(subField) => (
        <subField.PermissionField {...props} onRemove={onRemove} />
      )}
    </form.AppField>
  );
};

const PermissionsField = ({
  ruleColumn,
  ctdsOptions,
  disabled,
  type,
  ctdPlaceholder,
  testId,
}) => {
  const { t } = useTranslation();
  const field = useFieldContext();

  const onAdd = useCallback(
    () =>
      field.pushValue({
        canCreate: false,
        canRead: false,
        canUpdate: false,
        canDelete: false,
        contentTypeDefinition: { id: null },
        type,
      }),
    [field, type],
  );

  const typePermissions = useMemo(
    () => field.state.value.filter(({ type: permType }) => type === permType),
    [field.state.value, type],
  );

  return (
    <>
      <div className="w-full hidden sm:grid grid-cols-2 items-center">
        {typePermissions.length > 0 && (
          <div className="grid col-start-2 grid-cols-4 w-full justify-items-center dark:text-slate-300">
            {ruleColumn.map((rule) => (
              <span key={rule.name} className="uppercase text-sm">
                {rule.label}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="w-full flex flex-col sm:grid sm:grid-cols-2 items-center gap-y-4">
        {field.state.value.map((permission, index) => {
          if (permission.type !== type) return null;
          return (
            <PermissionAppField
              key={index}
              index={index}
              ctdsOptions={ctdsOptions}
              ruleColumn={ruleColumn}
              ctdPlaceholder={ctdPlaceholder}
              disabled={disabled}
              type={type}
              testId={testId}
            />
          );
        })}
      </div>

      <div className="flex flex-col w-full mt-6">
        <Button
          onClick={onAdd}
          buttonSize="xs"
          disabled={disabled}
          additionalClasses="w-fit h-8 text-base 2xl:text-base"
          {...getTestProps(testId, 'add', 'testId')}
        >
          {t('ApiKeys.AddRule')}
        </Button>
      </div>
    </>
  );
};

export default PermissionsField;

PermissionsField.propTypes = {
  ruleColumn: PermissionField.propTypes.ruleColumn,
  ctdsOptions: PermissionField.propTypes.ctdsOptions,
  disabled: PermissionField.propTypes.disabled,
  type: PermissionField.propTypes.type,
  ctdPlaceholder: PermissionField.propTypes.ctdPlaceholder,
  testId: PermissionField.propTypes.testId,
};

PermissionsField.defaultProps = {
  ruleColumn: [],
  ctdsOptions: [],
  disabled: false,
  type: 'CTD',
  ctdPlaceholder: '',
  testId: '',
};

import { useMemo } from 'react';
import { useFieldContext } from './form';

export const useFieldErros = () => {
  const field = useFieldContext();

  const error = useMemo(() => {
    if (!field.state.meta.isTouched) return;
    if (field.state.meta.errors.length) {
      return field.state.meta.errors.map((error) =>
        typeof error === 'string' ? error : error.message,
      );
    }
  }, [field.state.meta.errors, field.state.meta.isTouched]);

  return error;
};

import { useCallback, useContext, useMemo, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import useLocalStorageState from 'use-local-storage-state';

// :: Contexts
import { defaultButtonsProps, useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';

// :: Hooks
import {
  useContentObject,
  useContentType,
  useContentObjectVersions,
  usePluginsSettings,
} from '../../hooks/api';
import useToken from '../../hooks/useToken';
import useOnce from '../../hooks/useOnce';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import ContentObjectVersions from '../../components/ContentObjectVersions/ContentObjectVersions';
import ContentObjectWebhooks from '../../components/ContentObjectWebhooks/ContentObjectWebhooks';
import Button from '../../components/Button/Button';
import ElementFromPlugin from '../../components/ElementFromPlugin/ElementFromPlugin';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarSaveAndLeaveButton from '../../components/Topbar/buttons/TopbarSaveAndLeaveButton';
import TopbarDuplicateButton from '../../components/Topbar/buttons/TopbarDuplicateButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import TopbarCancelButton from '../../components/Topbar/buttons/TopbarCancelButton';
import TopbarSaveButton from '../../components/Topbar/buttons/TopbarSaveButton';
import TopbarDeleteButton from '../../components/Topbar/buttons/TopbarDeleteButton';

// :: Components Inner
import ContentObjectBacklinks from './ContentObjectBacklinks/ContentObjectBacklinks';

// :: Form
import ContentObjectForm from '../../form/ContentObjectForm/ContentObjectForm';

// :: Helpers
import { getSortedBy, getTestProps } from '../../lib/helpers';

// :: Lib Api
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import {
  archiveContentObject,
  listWebhooks,
  publishContentObject,
  unpublishContentObject,
} from '../../lib/flotiq-client';
import { saveNewCTO, updateCTO } from '../../lib/flotiq-client/api-helpers';
import { FormAddSidebarPanelEvent } from '../../lib/flotiq-plugins/plugin-events/FormAddSidebarPanelEvent';

// :: Icons
import {
  WarningIcon,
  ArrowCollapseLeftIcon,
  ArrowCollapseRightIcon,
  BoxWithArrowIcon,
  WarningTriangleRedIcon,
  ArrowLeftCurvedIcon,
} from '../../images/shapes';

// :: Layout
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

// :: Hooks
import useFirstLoading from '../../hooks/useFirstLoading';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import usePluginResults from '../../hooks/usePluginResults';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';
import { useBacklinks } from '../../hooks/api/useBacklinks';
import TopbarActionButton from '../../components/Topbar/buttons/base/TopbarActionButton';
import TopbarPublishButton from '../../components/Topbar/buttons/TopbarPublishButton';
import TopbarStatus from '../../components/Topbar/status/TopbarStatus';
import { useSpaceConstraint } from '../../hooks/api/useSpaceConstraint';

const versionsHookParams = {
  limit: 1000,
  page: 1,
};

const USER_PLUGINS_PARAMS = {
  limit: 1000,
  page: 1,
};

/**
 * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
 */
const AddContentObject = ({ duplicate, testId, mode }) => {
  const { t } = useTranslation();
  const navigateOnSave = useRef();
  const modal = useModals();
  const jwt = useToken();
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const navigate = useNavigate();
  const { permissions } = useContext(UserContext);
  const { setDirty } = useContext(DirtyHandlerContext);

  const formikRef = useRef();
  const [formikState, setFormikState] = useState();

  const [user, setUser] = useLocalStorageState('cms.user');

  const { data: userPlugins } = usePluginsSettings(USER_PLUGINS_PARAMS);

  const { reload: reloadConstraint } = useSpaceConstraint(
    'content-objects-count',
  );

  const { contentTypeName, id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTransisting, setIsTransisting] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);

  const { navigateGrid, gridLink } = useGridNavigate(
    `objects-${contentTypeName}`,
    buildUrlWithSpace(`content-type-objects/${contentTypeName}`),
  );

  const {
    backlinks,
    backlinkContentTypes,
    backlinkPagination,
    setBacklinksPage,
  } = useBacklinks(id, contentTypeName, duplicate);

  const {
    data: ctoVersions,
    errors: contentVersionsErrors,
    reload: reloadContentVersions,
  } = useContentObjectVersions(contentTypeName, id, versionsHookParams);

  const [customWebhooks, setCustomWebhooks] = useState([]);

  const {
    entity: contentObject,
    isLoading: contentObjectIsLoading,
    updateEntity: updateContentObject,
    partialUpdateEntity: partialUpdateContentObject,
    deleteEntity: deleteContentObject,
    errors: contentObjectErrors,
    reload: contentObjectReload,
  } = useContentObject(contentTypeName, id);

  const fetchWebhooks = useCallback(async () => {
    if (!permissions.canCo('_webhooks')) return;
    try {
      // List webhooks with content type definitions based on content type name
      const { body, status } = await listWebhooks(jwt, space, {
        filters: JSON.stringify({
          'actions[*].action': { type: 'contains', filter: '"Custom"' },
          'content_type_definitions[*].content_type_definition_name': {
            type: 'contains',
            filter: `"${contentTypeName}"`,
          },
          enabled: { type: 'equals', filter: true },
        }),
      });

      // List webhooks with content type definitions that is for All (default)
      const { body: bodyDefault, status: statusDefault } = await listWebhooks(
        jwt,
        space,
        {
          filters: JSON.stringify({
            'actions[*].action': { type: 'contains', filter: '"Custom"' },
            content_type_definitions: {
              type: 'contains',
              filter: '[]',
            },
            enabled: { type: 'equals', filter: true },
          }),
        },
      );

      const webhooksList = [...body.data, ...bodyDefault.data];
      const mapFromWebhooks = new Map(webhooksList.map((c) => [c.id, c]));
      const uniqueList = [...mapFromWebhooks.values()];

      checkResponseStatus(body, status);
      checkResponseStatus(bodyDefault, statusDefault);

      setCustomWebhooks(uniqueList);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message ? error.message : t('ContentForm.CouldntFetch'),
        );
      }
    }
  }, [permissions, jwt, space, contentTypeName, t]);

  useOnce(fetchWebhooks);

  const handleDeleteObject = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteContentObject({ contentTypeName });
      checkResponseStatus(body, status);
      toast.success(t('ContentForm.Deleted'));

      setDirty(false);
      navigateGrid();
      reloadConstraint();
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message ? error.message : t('ContentForm.CouldntDelete'),
        );
      }
    }
  }, [
    modal,
    deleteContentObject,
    contentTypeName,
    t,
    setDirty,
    navigateGrid,
    reloadConstraint,
  ]);

  const deleteObject = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(t('ContentForm.ConfirmDelete'), 'delete-modal', () =>
      handleDeleteObject(),
    );
    setIsDeleting(false);
  }, [handleDeleteObject, modal, t]);

  const {
    entity: contentType,
    errors: contentTypeErrors,
    status: contentTypeStatus,
    isLoading: contentTypeLoading,
  } = useContentType(contentTypeName);

  const firstLoading = useFirstLoading(
    (!contentTypeLoading || !contentTypeName) &&
      (!contentObjectIsLoading || !id),
    contentTypeName + id,
  );

  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(contentVersionsErrors);
  useApiErrorsToast(contentObjectErrors);

  const { canCreate, canDelete, canUpdate, canRead } = useMemo(
    () => permissions.getCoPermissions(contentTypeName) || {},
    [contentTypeName, permissions],
  );

  const notAllowed = useMemo(
    () => (contentType?.internal && contentType?.name !== '_tag') || !canRead,
    [contentType, canRead],
  );

  const label = useMemo(
    () =>
      contentType?.name === '_tag' ? t('Global.Tag') : contentType?.label || '',
    [contentType?.label, contentType?.name, t],
  );

  const pageTitle = useMemo(() => {
    if (duplicate) return 'Duplicate';
    else if (id) return 'Edit';
    return 'Add';
  }, [id, duplicate]);

  const saveNewObject = useCallback(
    async (values) => {
      setIsSaving(true);
      const [formikValues, hasErrors] = await saveNewCTO(
        jwt,
        space,
        values,
        contentTypeName,
        user,
        setUser,
        t,
      );
      setIsSaving(false);
      if (!hasErrors) {
        reloadConstraint();
        navigate(
          buildUrlWithSpace(
            `content-type-objects/edit/${contentTypeName}/${formikValues[0].id}`,
          ),
        );
      }
      return formikValues;
    },
    [
      jwt,
      space,
      contentTypeName,
      user,
      setUser,
      t,
      reloadConstraint,
      navigate,
      buildUrlWithSpace,
    ],
  );

  const updateObject = useCallback(
    async (values) => {
      setIsSaving(true);

      const [formikValues] = await updateCTO(
        values,
        contentTypeName,
        permissions.hasCoLimitedFields(contentTypeName)
          ? partialUpdateContentObject
          : updateContentObject,
        t,
      );

      await reloadContentVersions();
      setIsSaving(false);
      return formikValues;
    },
    [
      contentTypeName,
      permissions,
      partialUpdateContentObject,
      updateContentObject,
      t,
      reloadContentVersions,
    ],
  );

  const handleVersionRestore = useCallback(
    async (oldContentObject) => {
      delete oldContentObject.id;
      delete oldContentObject.internal;

      if (formikRef.current) {
        await formikRef.current.setValues(oldContentObject);

        setTimeout(async () => {
          await formikRef.current.submitForm();
          contentObjectReload();
        });
      }
    },
    [contentObjectReload],
  );

  const emptyCTD = useMemo(() => {
    if (firstLoading) {
      return (
        <Loader
          size={'small'}
          type={'spinner-grid'}
          testId={testId ? `${testId}-loader` : ''}
        />
      );
    }
    if (notAllowed) {
      toast.error(t('ContentForm.CouldntFind', { contentTypeName: label }));
    }
    return (
      <Heading
        level={2}
        additionalClasses={twMerge(
          'text-3xl md:text-4xl leading-8 dark:text-white',
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('ContentForm.CouldntFind')}
          />
          {contentTypeStatus === 404 || notAllowed
            ? t('ContentForm.CouldntFind', { contentTypeName })
            : t('ContentForm.CouldntFetch')}
        </div>
      </Heading>
    );
  }, [
    firstLoading,
    notAllowed,
    testId,
    t,
    contentTypeStatus,
    contentTypeName,
    label,
  ]);

  const showForm = useMemo(
    () => !notAllowed && !firstLoading && contentType,
    [notAllowed, firstLoading, contentType],
  );

  const showConfirmationModal = useCallback(
    (action) =>
      modal({
        title: (
          <div className="inline-flex text-red font-bold text-3xl items-center">
            <WarningTriangleRedIcon className="h-5 mr-2.5" />
            {t('Global.Warning')}
          </div>
        ),
        content:
          action === 'archive'
            ? t('ObjectStatus.Confirmation.Archive.Content')
            : t('ObjectStatus.Confirmation.Unpublish.Content'),
        buttons: [
          {
            key: 'archive',
            label:
              action === 'archive'
                ? t('ObjectStatus.Confirmation.Archive.Confirm')
                : t('ObjectStatus.Confirmation.Unpublish.Confirm'),
            result: true,
            color: 'red',
            iconImage: <BoxWithArrowIcon className="w-3 min-w-3" />,
            iconPosition: 'start',
          },
          {
            ...defaultButtonsProps.cancel,
            label: t('Global.Cancel'),
            result: false,
          },
        ],
      }),
    [modal, t],
  );

  const handleChangeObjectStatus = useCallback(
    async (action) => {
      if (action === 'unpublish' || action === 'archive') {
        const result = await showConfirmationModal(action);
        if (!result) return;
      }

      if (contentObject.internal.status !== action) {
        setIsTransisting(true);

        const methods = {
          unpublish: unpublishContentObject,
          archive: archiveContentObject,
          publish: publishContentObject,
        };

        try {
          const apiMethod = methods[action] || publishContentObject;
          const { body, status } = await apiMethod(jwt, space, {
            objectType: contentTypeName,
            objectId: id,
          });

          checkResponseStatus(body, status);
          toast.success(t('ObjectStatus.SuccessStatusUpdate'));
          contentObjectReload();
        } catch (error) {
          toast.error(error.message);
        }
        await reloadContentVersions();
        setIsTransisting(false);
      }
    },
    [
      contentObject?.internal?.status,
      showConfirmationModal,
      reloadContentVersions,
      jwt,
      space,
      contentTypeName,
      id,
      t,
      contentObjectReload,
    ],
  );

  const handlePublish = useCallback(
    () => handleChangeObjectStatus('publish'),
    [handleChangeObjectStatus],
  );

  const handleUnpublish = useCallback(
    () => handleChangeObjectStatus('unpublish'),
    [handleChangeObjectStatus],
  );

  const handleArchive = useCallback(
    () => handleChangeObjectStatus('archive'),
    [handleChangeObjectStatus],
  );

  const handleShowSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);

  const isFormDisabled = useMemo(() => {
    if (isSaving || isDeleting) return true;
    if (id) return !canUpdate;
    return !canCreate;
  }, [canCreate, canUpdate, id, isDeleting, isSaving]);

  const formUniqueKey = `${
    contentObject?.internal?.latestVersion || 'current'
  }`;

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
   */
  const pluginSidebarPanels = usePluginResults(
    'flotiq.form.sidebar-panel::add',
    FormAddSidebarPanelEvent,
    {
      contentType,
      contentObject,
      disabled: isFormDisabled,
      duplicate,
      create: !id || duplicate,
      userPlugins,
      formik: formikState,
      formUniqueKey,
    },
  );

  const currentStatus = contentObject?.internal?.status;
  const draftPublic = contentType?.draftPublic;

  const hasUnpublishActionButton = useMemo(
    () =>
      id &&
      !duplicate &&
      draftPublic &&
      ['public', 'modified'].includes(currentStatus),
    [id, currentStatus, duplicate, draftPublic],
  );

  const publishButtonDisabledReason = useMemo(() => {
    if (!id) {
      return t('ObjectStatus.Tooltip.PublishDisabledNotSaved');
    }
    if (currentStatus === 'public') {
      return t('ObjectStatus.Tooltip.PublishDisabledAlreadyPublished');
    }
    if (formikState?.dirty) {
      return t('ObjectStatus.Tooltip.PublishDisabledFormChanged');
    }
  }, [id, currentStatus, formikState?.dirty, t]);

  const topbarStatus = useMemo(() => {
    if (!id || !currentStatus || duplicate) return '';
    if (!draftPublic) return 'saved';
    return currentStatus;
  }, [id, currentStatus, duplicate, draftPublic]);

  const topbarStatusTooltip = useMemo(() => {
    if (topbarStatus === 'saved') {
      return t('ObjectStatus.Tooltip.EnableDraftPublic');
    }
  }, [t, topbarStatus]);

  const isSaveButton = (canUpdate && id) || (canCreate && !id);
  const isSaveDisabled = !!id && !duplicate && !formikState?.dirty;

  return (
    <PageLayout
      id={contentTypeName === '_tag' ? 'tags' : `contentTypeObjects-${mode}`}
      page={
        contentTypeName === '_tag'
          ? 'tags'
          : `content/${label || contentTypeName}`
      }
      menuItemOpen={contentTypeName === '_tag' ? '' : 'content'}
      title={t(`ContentForm.${pageTitle}`, { contentTypeName: label })}
      buttonsDisabled={isSaving || isDeleting || isTransisting}
      breadcrumbs={
        <TopbarBreadcrumbs
          parentTitle={t('ObjectsOfType.Title', { contentTypeName: label })}
          parentLink={gridLink}
        />
      }
      buttons={
        <>
          <TopbarCancelButton link={gridLink} />
          {isSaveButton && (
            <TopbarSaveButton
              form="cto-form"
              isLoading={isSaving}
              navigateOnSave={navigateOnSave}
              label={
                draftPublic ? t('ObjectStatus.SaveDraft') : t('Global.Save')
              }
              disabled={isSaveDisabled}
            />
          )}
          {draftPublic && (
            <TopbarPublishButton
              onChange={handlePublish}
              isLoading={isTransisting}
              disabled={!!publishButtonDisabledReason}
              tooltip={publishButtonDisabledReason}
              tooltipPlacement="bottomCenter"
            />
          )}
          <TopbarActionMenu>
            {canCreate && id && !duplicate && (
              <TopbarDuplicateButton
                link={buildUrlWithSpace(
                  `content-type-objects/duplicate/${contentTypeName}/${id}`,
                )}
              />
            )}
            {isSaveButton && (
              <TopbarSaveAndLeaveButton
                form="cto-form"
                navigateOnSave={navigateOnSave}
                disabled={isSaveDisabled}
              />
            )}
            {draftPublic && (
              <TopbarPublishButton
                onChange={handlePublish}
                isLoading={isTransisting}
                disabled={!!publishButtonDisabledReason}
                actionButton={true}
              />
            )}
            {hasUnpublishActionButton && (
              <>
                <TopbarActionButton
                  color="red"
                  label={t('ObjectStatus.Transition.unpublish')}
                  iconImage={<ArrowLeftCurvedIcon className="w-3 min-w-3" />}
                  onClick={handleUnpublish}
                  additionalIconClasses="ml-0.5 mr-3"
                />
                <TopbarActionButton
                  color="red"
                  label={t('ObjectStatus.Transition.archive')}
                  iconImage={<BoxWithArrowIcon className="w-3 min-w-3" />}
                  onClick={handleArchive}
                  additionalIconClasses="ml-0.5 mr-3"
                />
              </>
            )}
            {canDelete && id && !duplicate && (
              <TopbarDeleteButton onClick={deleteObject} />
            )}
          </TopbarActionMenu>
        </>
      }
      status={
        topbarStatus ? (
          <TopbarStatus
            label={t(`ObjectStatus.Status.${topbarStatus}`)}
            status={topbarStatus}
            testId={testId}
            tooltip={topbarStatusTooltip}
          />
        ) : null
      }
      testId={testId}
    >
      {showForm ? (
        <div className={predefinedLayoutClasses.withSidebar}>
          <div
            className={twMerge(
              predefinedLayoutClasses.leftColumnWhite,
              'relative',
              !showSidebar && 'xl:col-span-7 xl:mr-16',
            )}
          >
            <div className="space-y-3 md:space-y-6 p-7 md:py-10 md:px-14">
              <ContentObjectForm
                key={pageTitle}
                contentType={contentType}
                contentObject={contentObject || {}}
                isEditing={!!(id && !duplicate)}
                onSubmit={id && !duplicate ? updateObject : saveNewObject}
                disabled={isFormDisabled}
                navigateOnSave={navigateOnSave}
                hasInitialData={!!id}
                userPlugins={userPlugins}
                formikRef={formikRef}
                formUniqueKey={formUniqueKey}
                setFormikState={setFormikState}
                {...getTestProps(testId, 'form', 'testId')}
              />
            </div>
            <Button
              onClick={handleShowSidebar}
              iconImage={
                showSidebar ? (
                  <ArrowCollapseRightIcon className="w-5 h-5 text-blue" />
                ) : (
                  <ArrowCollapseLeftIcon className="w-5 h-5 text-blue" />
                )
              }
              additionalIconClasses={'min-w-10 w-10'}
              buttonColor="borderless"
              additionalClasses={twMerge(
                'hidden absolute top-2.5 right-1.5',
                process.env.REACT_APP_ENABLE_CTO_FORM_RESIZE.split(',').join(
                  ',',
                ) === 'true' && 'xl:flex',
              )}
              noPaddings
              {...getTestProps(testId, 'toggle-sidebar', 'testId')}
            />
          </div>
          <div
            className={twMerge(
              predefinedLayoutClasses.rightColumn,
              !showSidebar &&
                'xl:border-none xl:absolute xl:right-0 xl:w-16 xl:p-0 xl:h-full xl:max-h-[calc(100%-55px)]',
            )}
            {...getTestProps(testId, 'sidebar-container')}
          >
            {!duplicate && (
              <ContentObjectInformations
                createdAt={contentObject?.internal?.createdAt}
                updatedAt={contentObject?.internal?.updatedAt}
                updatedAtText={
                  draftPublic && t('TypeDefinitionCard.LastUpdated')
                }
                lastPublishedAt={contentObject?.internal?.publishedAt}
                additionalClasses={twMerge('order-20')}
                isPinned={!showSidebar}
                testId={testId}
                id={'content_object-informations'}
              />
            )}

            {customWebhooks.length > 0 && !duplicate && id && (
              <ContentObjectWebhooks
                webhooks={customWebhooks}
                contentObjectId={id}
                contentTypeDefinitionName={contentTypeName}
                additionalClasses={twMerge(
                  'order-30',
                  !showSidebar && 'xl:hidden',
                )}
                testId={testId}
              />
            )}

            {ctoVersions?.length > 0 && !duplicate && (
              <ContentObjectVersions
                versions={getSortedBy(ctoVersions, 'version')}
                onRestore={handleVersionRestore}
                currentChanges={formikRef.current?.values}
                isDirty={formikRef.current?.dirty}
                contentType={contentType}
                additionalClasses={twMerge(
                  'order-40',
                  !showSidebar && 'xl:hidden',
                )}
                testId={testId}
              />
            )}

            {!duplicate && backlinks?.length > 0 && (
              <ContentObjectBacklinks
                backlinks={backlinks}
                contentTypes={backlinkContentTypes}
                pagination={backlinkPagination}
                onPageChange={setBacklinksPage}
                additionalClasses={twMerge(
                  'order-50',
                  !showSidebar && 'xl:hidden',
                )}
              />
            )}

            {pluginSidebarPanels?.length > 0 && showSidebar && (
              <ElementFromPlugin results={pluginSidebarPanels} />
            )}
          </div>
        </div>
      ) : (
        <div className={predefinedLayoutClasses.whiteBox}>{emptyCTD}</div>
      )}
    </PageLayout>
  );
};

export default AddContentObject;

AddContentObject.propTypes = {
  /**
   * If object is duplicating
   */
  duplicate: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
  /**
   * Content Object mode
   */
  mode: PropTypes.string,
};

AddContentObject.defaultProps = {
  duplicate: false,
  testId: '',
  mode: '',
};
